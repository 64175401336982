// Language select
.language-dropdown {
    .language-select {
        &__control {
            background-color: transparent;
            // border: none;
            min-height: auto;
            height: 30px;
            width: 30px;
            border-radius: 100%;
            border-color:  var(--input-border-color);
            cursor: pointer;
            &:focus,
            &:hover,
            &--is-focused {
                border-color: var(--primary);
                outline: none;
                // background-color: rgba($color: $primary, $alpha: .2);
                box-shadow: none;
            }
        }
        &__value-container {
            padding: 0;
        }
        // Selected Value
        &__single-value {
            margin: 0;
            .selected-country {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                text-align: center;
                .country-flag {
                    font-size: size(14);
                    line-height: normal;
                }
                .language-title {
                    display: none;
                }
            }
        }

        // Indicator
        &__indicator {
            display: none;
            padding: 0;
        }
        &__indicator-separator {
            display: none;
        }

        // Menu
        &__menu {
            right: 0;
            margin-top: 4px;
            width: auto;
            overflow: hidden;
            background-color: var(--white);
            box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
            .selected-country {
                display: flex;
                align-items: center;
                font-size: size(14);
                .country-flag {
                    margin-right: 8px;
                    line-height: normal;
                }
                .language-title {
                    font-size: size(14);
                }
            }
        }
        &__menu-list {
            padding: 0;
        }
        &__option {
            // background-color: rgba($color: $primary, $alpha: .2);
            padding: 5px 12px;
            font-size: size(14);
            cursor: pointer;
            &:hover, &:focus,
            &--is-focused,
            &--is-selected {
                color: var(--dark);
                background-color: rgba($color: $primary, $alpha: .1);
            }
        }
    }
}
.custom-select{
    &.form-select--is-disabled{
        background-color:  rgba(0, 0, 0, 0.07);
    }
}