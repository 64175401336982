

// Accordion
.accordion {
  .accordion-item {
    // background-color: var(--white);
    border-width: size(1);
    border-style: solid;
    border-color: $accordion-border-color;

    & ~ .accordion-item {
      margin-top: size(-1);
    }
  }
}

// Accordion Item
// .accordion-item {
//   border: 0;
// }

// Accordion Button
.accordion-button {
  color: var(--black);
  &::after {
    content: "\e98e";
    font-family: $ff-icomoon;
    font-size: size(16);
    width: unset;
    height: unset;
    // width: size(8);
    // height: size(8);
    background-image: none;
    // border-width: 0 0 size(2) size(2);
    // border-style: solid;
    // border-color: currentColor;
    // transform: rotateZ(-45deg);
  }
  &:not(.collapsed) {
    // background-color: var(--white);
    box-shadow: none;
    &::after {
      background-image: none;
      transform: rotateZ(180deg);
    }
  }
  &:focus {
    box-shadow: none;
  }
  &:hover {
    z-index: unset;
  }
  &:focus-visible {
    outline: none;
  }
}

// for Body
.accordion-body {
  // background-color: var(--white);
  border-width: size(1) 0 0;
  border-style: solid;
  border-color: var(--global-border-color);
  @include css3-prefix(transition, background-color 0.15s ease-in-out);
}
