.wizard-layout {

  .wizard-layout-steps,
  .step-progress {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    padding: 0;
    counter-reset: li;

    li {
      width: 100%;
      display: grid;
      place-items: center;
      position: relative;
      z-index: 1;

      &:not(.active) {
        &::before {
          background: lighten($primary, 15);
        }
      }

      &:first-child {
        &::after {
          right: 0;
          left: unset;
          width: 50%;
        }
      }

      &:last-child {
        &::after {
          width: 50%;
        }
      }

      &.active {
        &::after {
          background: var(--primary-500);
        }
      }

      // for Counters
      &::before {
        counter-increment: li;
        content: counter(li);
        display: grid;
        place-items: center;
        background: $primary;
        border-radius: 50%;
        margin: 0 auto;
        width: size(50);
        height: size(50);
        font-size: size(20);
        line-height: size(45);
        color: var(--white-500);
      }

      // for Line
      &::after {
        content: '';
        width: 100%;
        height: size(2);
        background: var(--gray-05);
        position: absolute;
        left: 0;
        top: size(25);
        z-index: -1;
      }
    }
  }

  fieldset {
    &:not(:first-of-type) {
      display: none;
    }
  }

  .nav-pills {
    li {
      width: 10%;
    }

    .nav-item {
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: size(2);
        background: var(--gray-05);
        position: absolute;
        left: 0;
        top: size(20);
        z-index: -1;
      }

      &:last-child {
        &::after {
          height: size(0);
          background: none;
        }
      }

      .nav-link {
        &.active {
          border-radius: size(50);

          &::after {
            background: var(--primary-500);
          }
        }
      }
    }
  }
}