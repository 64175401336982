
.swal2-title {
  @include font(24, 30);
}

.swal2-styled {
  &.swal2-cancel,
  &.swal2-confirm {
    display: inline-flex;
    align-items: center;
    padding: size(6) size(12);
    font-size: size(16);
    line-height: 1.5;
    font-weight: 400;
    position: relative;
    overflow: hidden;
    color: $white;
  }

  &.swal2-confirm {
    background-color: $danger;
    border-color: $danger;
  }
}
