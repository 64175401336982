
[class*="ag-theme-"] {
  --ag-font-family: #{$font-family-base};
  --ag-font-size: 14px;
  --ag-data-color: var(--black);
  --ag-border-color: transparent;
  --ag-header-foreground-color: var(--black);
  --ag-foreground-color: #{$black};
  --ag-background-color: #{$white};
  --ag-header-background-color: #{$white};
  --ag-alpine-active-color: #{$primary};
  --ag-row-hover-color: transparent;
  --ag-row-border-width: 0;
  --ag-borders-row: none;
  --ag-borders-critical: none;
  --ag-selected-row-background-color: rgb(99 98 231 / 5%);
  --ag-cell-horizontal-border: solid transparent;
  --ag-odd-row-background-color: transparent;
  --ag-even-row-background-color: transparent;
  
  

  // for Checkbox
  --ag-checkbox-background-color: transparent;
  --ag-checkbox-border-radius: 4px;
  --ag-checkbox-unchecked-color: #{$primary};
  --ag-checkbox-checked-color: #{$primary};
  --ag-checkbox-indeterminate-color: #{$primary};
}

// for CSS Variables of Light & Dark Themes ~~~
:root {

  //////////////////
  // for Light Theme


  /////////////////
  // for Dark Theme
  &[data-theme="dark"] {
    [class*="ag-theme-"] {
      --ag-foreground-color: rgba(90, 104, 119, 1);
      --ag-background-color: mix($dark, white, 35%);
      --ag-header-background-color: #252525;
    }

    .ag-row-hover {
      background-color: rgba($primary, 0.15) !important;
    }
  }
}

// for onMouseOver hover of .ag-row
.ag-row-hover {
  background-color: rgba($black, 0.075) !important;

  .ag-cell {
    &:first-of-type {
      border-left-color: $primary;
    }
  }
}
// for On Row Selected
.ag-row-selected {
  .ag-cell {
    &:first-of-type {
      border-left-color: $primary;
    }
  }
}

.ag-theme-custom {
  .ag-root-wrapper {
    border-width: 0 size(1) size(1);
    border-style: solid;
    border-color: var(--gray-100);
    border-radius: 0;
    border: none;
    font-family: $font-family-base;
  }

  .ag-header {
    border-radius: 0;
    border-bottom: none;

    .ag-header-cell {
      &:first-of-type {
        border-left: size(3) solid transparent;
      }
    }
  }

  .ag-header-row {
    border-top: 1px solid var(--global-border-color);
    border-bottom: 1px solid var(--global-border-color);
  }

  // .ag-row {
  //   border-bottom: 0;
  // }
  .ag-selection-checkbox,
  .ag-cell > div, .ag-cell > div img {
    height: 100%;
  }
  .form-switch {
    padding-top: 6px;
  }
  .ag-cell > span {
    line-height: 50px;
  }
  .link-dark, a:not([href]):not([class]) {
    display: inline-flex;
    align-items: center;
  }
  .email-status-badge {
    flex: 0 0 auto;
  }

  // .ag-row-odd {
  //   background-color: transparent;
  // }

  // .ag-row-even {
  //   background: transparent;
  // }

  // .ag-cell-focus {
  //   border: none;
  // }

  .ag-paging-panel {
    border-top: 0;
  }

  .ag-checkbox-input-wrapper {
    &:focus-within {
      box-shadow: none;
    }
  }
  .ag-selection-checkbox{
    align-self: baseline;
  }
}

// for Row Selection Checkbox
.ag-checkbox-input {
  cursor: pointer;
}

// for tbody and thead Cell
.ag-header-cell-text,
.ag-cell {
  font-size: size(14);
  letter-spacing: normal;
}
.ag-cell {
  line-height: 3.5;
  font-weight: $fw-regular;
  // padding: 3px 12px;

  &:first-of-type {
    border-left: size(4) solid transparent;
  }
  // > div {
  //   justify-content: center;
  //   height: 100%;
  // }
}
// for thead Cell
.ag-header-cell-text {
  font-weight: $fw-bold;
}

// for tooltip in action column
.ag-cell-value, .ag-group-value{
  &[col-id="action"]{
    overflow: unset;
  }
}
.ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
  height: 100%;
}