[data-theme="dark"] {
  .offcanvas {
    .btn-close {
      filter: invert(0.5);
    }
  }
}

.offcanvas {
  z-index: 1053;
  background: var(--white);
  border-radius: size(10);
  @include css3-prefix(box-shadow, var(--offcanvass-shadow));

  // for Placements
  &.offcanvas-start {
    @include media-breakpoint-up(sm) {
      top: size(30);
      left: size(30);
      bottom: size(30);
      border-right: 0;
    }
  }

  &.offcanvas-end {
    @include media-breakpoint-up(sm) {
      top: size(30);
      right: size(30);
      bottom: size(30);
      border-left: 0;
    }
  }

  &.offcanvas-top {
    @include media-breakpoint-up(sm) {
      top: size(30);
      left: size(30);
      right: size(30);
      border-bottom: 0;
    }
  }

  &.offcanvas-bottom {
    @include media-breakpoint-up(sm) {
      bottom: size(30);
      left: size(30);
      right: size(30);
      border-top: 0;
    }
  }

  // for Small size
  &.offcanvas-size-sm {
    @include media-breakpoint-up(sm) {
      width: size(350);
    }
  }

  // for Large size
  &.offcanvas-size-lg {
    @include media-breakpoint-up(sm) {
      width: size(500);
    }
  }

  // for Large size
  &.offcanvas-size-25 {
    @include media-breakpoint-up(sm) {
      width: calc(35vw - size(30));
    }
  }

  &.offcanvas-size-35 {
    @include media-breakpoint-up(sm) {
      width: calc(35vw - size(30));
    }
  }

  &.offcanvas-size-50 {
    @include media-breakpoint-up(sm) {
      width: calc(50vw - size(30));
    }
  }

  &.offcanvas-size-75 {
    @include media-breakpoint-up(sm) {
      width: calc(75vw - size(30));
    }
  }

  .offcanvas-header {
    border-bottom: size(1) solid var(--offcanvas-border);
  }

  .offcanvas-footer {
    padding:  $offcanvas-padding-y $offcanvas-padding-x;
    border-top: size(1) solid var(--offcanvas-border);
  }

  .offcanvas-title {
    font-size: size(16);
    font-weight: $fw-semibold;
    color: var(--dark-500);
  }
}

.offcanvas-backdrop,
.modal-backdrop {
  background-color: var(--white);
  opacity: 0.75;
  z-index: 1052;
  &.show {
    opacity: 0.75;
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background-repeat: no-repeat;
  //   background-size: 100%;
  //   background-position: center center;
  // }
}