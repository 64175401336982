
// [data-theme=dark] {
// 	.nav-tabs-content {
// 		.tab-pane {
// 			border: size(1) solid rgba($white, 0.20);
// 		}
// 	}
// }

// Global Style
.nav-tabs,
.nav-pills {
	border-color: var(--input-border-color);
	.nav-link {
		.bx, .bxs {
			vertical-align: middle;
		}
	}
}

// Nav - Pills
// .nav-pills {
// 	color: $primary;

// 	.nav-item {
// 		color: inherit;

// 		.nav-link {
// 			color: inherit;
// 			background-color: transparent;
// 			&:active,
// 			&.active {
// 				color: inherit;
// 				background-color: currentColor;

// 				& > * {
// 					color: var(--white);
// 				}
// 			}
// 			&:disabled {
// 				opacity: 0.5;
// 				filter: saturate(0.5);
// 				cursor: not-allowed;
// 			}
// 		}

// 		& > * {
// 			 & > *{
// 				color: inherit;
// 			 }
// 		}
// 	}
// }

// Nav Pills Content
// .nav-pills-content {
// 	padding: size(16);
// 	margin-top: size(16);
// 	border-width: size(1);
// 	border-style: solid;
// 	border-color: var(--nav-pills-tabs-border-color);
// 	border-radius: size(4);
// }

// Nav - Tabs
.nav-tabs {
	& > .nav-item,
	& > .nav-link {
		&:not(:last-child) {
			margin-inline-end: size(20);
		}
	}

	.nav-link {
		padding: size(16) 0;
		font-size: size(16);
		font-weight: $fw-medium;
		color: var(--black);
		border: 0;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 0;
			height: 3px;
			background-color: currentColor;
			border-radius: 1.5px;
			color: inherit;
			@include css3-prefix(transition, width 0.35s ease-in-out);
		}

		&:hover,
		&.active,
		&:focus {
			color: $primary;
			background-color: transparent;

			&:not(:disabled),
			&:not([disabled]) {
				&::before {
					width: 100%;
				}
			}
		}

		&:disabled, &[disabled] {
			color: var(--black);
			opacity: 0.5;
			filter: saturate(0.5);
			cursor: not-allowed;
		}

		& > * {
			&:not(:only-child) {
				&:first-child {
					&.bx, &.bxs {
						margin-inline-end: size(8);
					}
					&:not([class*="bx"]) {
						margin-inline-end: size(8);
					}
				}
			}
		}
	}
}


// Nav Tabs Content
.nav-tabs-content {
	.tab-pane {
		padding: size(20) 0;
	}
}
