:root {

  //////////////////
  // for Light Theme
  .messages-content-time {
    .msg-content{
      color: $dark;
    }
    .msg-time{
      color: $dark;
    }
  }

  /////////////////
  // for Dark Theme
  &[data-theme="dark"] {
    .messages-content-time {
      .msg-content{
        color: rgba($white, 0.8);
      }
      .msg-time{
        color: rgba($white, 0.8);
      }
    }
  }
}

// Custom Dropdowns
.ctm-dropdown {

  padding: 0;
  background: var(--white);
  display: block;
  opacity: 0;
  transform: translate(0, size(45)) !important;
  visibility: hidden;
  transition: 0.5s;
  inset: unset !important;
  right: size(60) !important;
  overflow: hidden;
  border: 0;
  @include media-breakpoint-down(sm){
    position: fixed!important;
    left: 0 !important;
    right: 0 !important;
    width: 100%;
    top: 55px !important;
    height: 100%;
  }
  &.show {
    display: block;
    opacity: 1;
    transform: translate(0, size(4)) !important;
    visibility: visible;
    transition: 0.5s;
    border-radius: size(10);
    @include media-breakpoint-down(sm){
      border-radius: size(0);
    }
  }

  .dropdown-header {
    border-bottom: size(1) solid var(--global-border-color);
    padding: size(10);

    .icon-wrapper {
      transform: scale(3.5) rotate(-41deg);
      display: inline-block;
      opacity: 0.1;

      .ico {
        font-size: size(18);
        position: absolute;
        left: size(-6);
        top: size(-10);
      }
    }

    .dropdown-title {
      font-size: size(16);
      letter-spacing: size(0.5);
      font-weight: $fw-semibold;
      color: var(--black);
    }
  }

  .dropdown-list {
    height: size(243);
    overflow-y: auto;
    @include media-breakpoint-down(sm){
      height: 100%;
    }
    li {
      border-bottom: size(1) solid var(--global-border-color);
      display: flex;
      align-items: center;
      &:last-of-type{
        border: 0;
      }
      .list-item-link {
        width: 85%;
        flex: 0 0 85%;
      }

      .close {
        display: inline-flex;
        position: absolute;
        right: 0;
        top: size(3);
      }

    }
  }
}


// Three Dosts Dropdown
.three-dots {
  top: 50%;
  left: 50%;
  width: size(20);
  height: size(20);
  border: 0;
  padding: 0;
  background: 0;

  .dot {
    width: size(3.33);
    height: size(3.33);
    background: var(--black);
    position: relative;
    margin: auto;
    border-radius: 100%;

    &::before,
    &::after {
      display: block;
      content: "";
      position: absolute;
      background: var(--black);
      width: size(3.33);
      height: size(3.33);
      border-radius: 100%;

    }

    &::before {
      top: size(-5);
    }

    &::after {
      bottom: size(-5);
    }
  }
}

.notification-item {
  display: flex;
  padding: size(10);
  text-decoration: none;
  text-align: left;
  font-size: size(11);
  opacity: 0.8;

  .messages {
    padding-left: size(10);
    font-size: size(14);
    width: calc(100% - size(40));
    position: relative;

    .messages-content-time {
      display: flex;
      flex-wrap: wrap;

      .msg-time {
        margin-left: auto;
        font-size: size(12);
        font-weight: $fw-medium;
        align-self: center;
      }
    }
  }
}

.user-image {
  width: size(40);
  height: size(40);
  border-radius: size(50);
  margin-bottom: auto;
}