:root {
  //////////////////
  // for Light Theme



  /////////////////
  // for Dark Theme
  &[data-theme=dark] {
    .dropdown-menu {
      background-color: #3A3A3A;
      box-shadow: 0 size(8) size(16) rgba($white, 0.15);

      &>li,
      &>.dropdown-item {
        &:not(:last-child) {
          border-bottom: size(1) solid rgba($white, 0.25);
        }
      }
    }
  }
}


// Dropdown Global styling
.dropdown-toggle {

  // to Remove Shadow in React
  &:focus {
    box-shadow: none !important;
  }

  // for Remove Dropdown Arrow
  &.no-caret {
    &::before,
    &::after {
      display: none;
    }
  }
}

.dropdown-menu {
  padding: 0 size(10);
  box-shadow: 0 size(8) size(16) rgba($black, 0.15);

  &>li,
  &>.dropdown-item {
    &:not(:last-child) {
      border-bottom: size(1) solid rgba($black, 0.1);
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: size(12) size(12);
    font-size: size(12);
    font-weight: $fw-semibold;
    @include css3-prefix(transition, all 0.3s ease);

    &:hover,
    &.active,
    &:active {
      background-color: transparent;
      color: $primary;
      @include css3-prefix(transform, translateX(1px));
    }

    &:focus-visible {
      outline: none;
    }

    span {
      line-height: 1;
      color: inherit;
      vertical-align: middle;
    }
  }
}

.onhover-dropdown {
  &:hover {
    .onhover-show-div {
      opacity: 1;
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      visibility: visible;
      border-radius: size(5);
      overflow: hidden;
    }
  }

  .onhover-show-div {
    box-shadow: 0 0 size(20) rgba (89, 102, 122, 0.1);
    transform: translateY(size(30));
    opacity: 0;
    visibility: hidden;
    right: 0;
    display: block;
    transition: all linear 0.3s;
  }
}

.dropdown-md {
  width: size(240);
}

.dropdown-lg {
  width: size(320);
}

.ctm-drp-toggle {
  border-radius: size(8);
  font-family: $font-family-base;
  font-weight: $fw-medium;
  @include font(13, 24);
  color: var(--dark);
  padding: size(7) size(16);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.with-brdr {
    border: size(1) solid $tertiary;
  }
}


// right side icon dropdown styling
.text-icon-right {
  .ctm-drp-toggle {
    .ico {
      margin-inline-start: size(9);
      font-size: size(13);
      line-height: 1;
      color: inherit;
    }
  }
}

// first img then text then icon dropdown styling
.img-text-icon {
  .ctm-drp-toggle {
    @include font(14, 24);
    padding: size(8);
    font-family: $font-family-base;

    .bx {
      margin-inline-start: size(8);
    }
  }

  .ctm-dropdown {
    padding: 0;
    border-radius: size(8);
    overflow: hidden;
    width: size(200);

    li {
      a {
        padding: size(12) size(16);
        @include font(13, 24);
        font-family: $font-family-base;
        color: var(--gray);
        display: flex;
        align-items: center;

        .ico,
        .bx {
          font-size: size(20) !important;
          margin-inline-end: size(10);
        }

        &:hover {
          color: var(--black);
          background: $primary;
        }
      }
    }
  }
}

.inner-bottom-blur {
  position: sticky;
  bottom: 0;
  width: 100%;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 70px;
    position: absolute;
    bottom: 0;
    background: var(--white);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.12088585434173671) 18%, rgba(255, 255, 255, 1) 82%);
    pointer-events: none;
  }
}

.topbar-wrapper {
  .ctm-drp-toggle {
    .username {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}