.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb-item {
  display: inline-flex;
  align-items: center;
  font-size: size(14);

  a {
    display: inline-flex;
    align-items: center;
    font-size: inherit;
    line-height: 1;
  }

  [class*="bx"] {
    font-size: size(16);

    &~* {
      padding-inline-start: size(4);
    }
  }

  &.active {
    a {
      color: var(--bs-breadcrumb-item-active-color);
    }
  }
}