.profile-container {
    padding: size(30);
    margin: 0 0 size(30) 0;
    background: var(--white);
    border-radius: $border-radius-lg;

  .profile-name {
      font-size: size(24);
      font-weight: $fw-semibold;
  }

  .edit-profile {
      text-align: right;
  }

  .profile-title {
      font-size: size(16);
      font-weight: $fw-semibold;
  }


}

.avatar-edit {
  width: size(120);
  height: auto;
  border-radius: size(100);
  border: $border-width solid $border-color;

}

.edit-user-form {
    .form-label {
        font-size: size(12);
    }

    .avatar-edit {
        img {
            border: size(3) solid rgba($color: var(--white), $alpha: 0.20);
        }
    }

    .file--upload {

        .btn {
            width: size(35);
            height: size(35);
            right: 0;
            top: size(80);

        }
    }

    .edit-image {
        position: relative;
        width: size(110);
        height: size(110);

        .user-image {
            background: rgba($color: $primary, $alpha: 0.05);
            border: size(5) solid rgba($color: $primary, $alpha: 0.05);
            width: size(110);
            height: size(110);
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: cover;
            }

            .img-upload {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $primary;
                width: size(35);
                height: size(35);
                border-radius: size(50);
                color: var(--white);
                bottom: 0;
                right: 0;
                cursor: pointer;
            }

            #attach-file {
                display: none;
            }
        }
    }
}
