
// 
.tags {
  display: inline-block;
  padding: size(6) size(12);
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: var(--global-border-color);
  border-radius: size(4);
  position: relative;
  font-size: size(16);
  line-height: size(24);
  font-weight: $fw-regular;
  color: var(--black);

  // for Hover
  &:hover {
    color: inherit;
  }

  // for Small Size
  &.tags-sm {
    padding: size(4) size(8);
    font-size: size(14);
    line-height: size(21);
  }

  // for Large Size
  &.tags-lg {
    padding: size(8) size(16);
    font-size: size(20);
    line-height: size(30);
  }

  // with a Dot - Inline START or END
  &.add-dot-start,
  &.add-dot-end {
    // for Dot Size
    --dot-size: 6px;

    display: inline-flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: var(--dot-size);
      height: var(--dot-size);
      background: currentColor;
      border-radius: 100%;
      color: inherit;
    }
  }
  &.add-dot-start {
    &::before {
      margin-inline-end: size(8);
    }
  }
  &.add-dot-end {
    flex-direction: row-reverse;
    &::before {
      margin-inline-start: size(8);
    }
  }
}

@each $color, $value in $theme-colors {
	.tags-#{$color} {
		border-color: $value;
		color: $value;

    &:hover {
      color: $value;
    }
	}
}
