

@each $name, $value in $my-border-radius {
  .radius-#{$name} {
    border-radius: $value !important;
  }
  .radius-top-#{$name} {
    border-top-left-radius: $value !important;
    border-top-right-radius: $value !important;
  }
  .radius-top-left-#{$name} {
    border-top-left-radius: $value !important;
  }
  .radius-top-right-#{$name} {
    border-top-right-radius: $value !important;
  }
  .radius-right-#{$name} {
    border-top-right-radius: $value !important;
    border-bottom-right-radius: $value !important;
  }
  .radius-bottom-#{$name} {
    border-bottom-left-radius: $value !important;
    border-bottom-right-radius: $value !important;
  }
  .radius-bottom-left-#{$name} {
    border-bottom-left-radius: $value !important;
  }
  .radius-bottom-right-#{$name} {
    border-bottom-right-radius: $value !important;
  }
  .radius-left-#{$name} {
    border-top-left-radius: $value !important;
    border-bottom-left-radius: $value !important;
  }
}
