.calendar-wrapper {
    display: inline-block;
    width: 100%;
    margin-bottom: 40px;
    .rbc {
        &-calendar {
            padding: 0;
            min-height: 700px;
        }
        &-toolbar {
            padding: 30px;
            margin-bottom: 0;
            border: 1px solid var(--global-border-color);
            border-bottom: none;
            border-radius: 8px 8px 0 0;
        }
        &-toolbar-label {
            font-size: size(35);
            font-weight: $fw-bold;
        }

        // Week section
        &-time-content {
            border-color: var(--global-border-color);
        }
        &-time-view {
            border-radius: 0 0 8px 8px;
            .rbc-row {
                min-height: auto;
            }
            .rbc-header {
                border-bottom: none;
            }
            .rbc-button-link {
                font-size: size(14);
                text-transform: uppercase;
                border-color: var(--global-border-color);
                font-weight: $fw-bold;
            }
        }
        &-time-slot {
            .rbc-label {
                display: inline-block;
                padding: 8px 5px;
                font-size: size(14);
                font-weight: $fw-bold;
            }
        }

        // Header button group
        &-btn-group {
            display: inline-flex;
            gap: 6px;
            button {
                @extend .btn, .btn-primary;
                border-radius: 4px !important;
                &:active:focus {
                    background-color: var(--primary);
                    border-color: var(--primary);
                    color: var(--white);
                }
                &.rbc-active {
                    @extend .btn, .btn-dark;
                    &:active:focus,&:hover,&:focus {
                        background-color: var(--dark);
                        border-color: var(--dark);
                        color: var(--white);
                        box-shadow: none;
                    }
                }
            }
        }
        &-month-view {
            border-color: var(--global-border-color);
            border-radius: 0 0 8px 8px;
        }
        &-month-row {
            + .rbc-month-row {
                border-color: var(--global-border-color);
            }
        }
    
        // Week Header
        &-header {
            padding: 16px 0;
            font-size: size(14);
            text-transform: uppercase;
            border-color: var(--global-border-color);
            + .rbc-header {
                border-color: var(--global-border-color);
            }
        }

        // Days
        &-day-slot {
            .rbc-event {
                min-height: 25px;
                align-items: center;
            }
            .rbc-event-content {
                line-height: normal;
            }
        }
        &-day-bg {
            border-color: var(--global-border-color);
            + .rbc-day-bg {
                border-color: var(--global-border-color);
            }
            &.rbc-today {
                background-color: rgba($color: $primary, $alpha: .2);
            }
            &.rbc-off-range-bg {
                background-color: rgba($color: $black, $alpha: .1);
            }
        }
        &-date-cell {
            padding: 12px 12px 0;
            button {
                font-size: size(20);
                font-weight: $fw-bold;
            }
        }

        // Event
        &-event {
            width: 95%;
            margin: 0 auto;
            background-color: var(--primary);
            &.rbc-selected {
                background-color: var(--success);
                outline: none;
                border-color: var(--success);
            }
        }
        &-event-content {
            font-size: size(14);
        }

        // Agenda
        &-agenda-view {
            .rbc-agenda-date-cell {
                border-right: 1px solid $border-color;
            }
            .rbc-agenda-empty {
                text-align: center;
                border: 1px solid $border-color;
                padding: 15px;
                border-radius: 0 0 8px 8px;
                font-size: 14px;
                color: $danger;
            }
        }

    }
}
// overlay
.rbc-overlay {
    border-radius: 8px;
    .rbc-overlay-header {
        padding: 4px 0;
        margin: 0 0 10px;
        font-weight: $fw-medium;
    }
    .rbc-event {
        border-radius: 4px;
        background-color: $primary;
        outline: none;
        &.rbc-selected {
            outline: none;
            background-color: $secondary;
        }
    }
}