
.baking-block {
  display: grid;
  place-items: center;
  width: 100%;
  max-width: size(650);
  min-height: calc(100vh - size(70));
  padding: size(16);
  margin: 0 auto;
  position: relative;
}

.filter-inner-list {
  max-height: 168px;
  overflow: auto;
}

.divider-line {
  position: relative;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 12px;
    width: 46%;
    height: 1px;
    background-color: $border-color;
    @include css3-prefix(transform, translateY(-50%));
  }
  &::before {
    left: auto;
    right: 12px;
  }
}