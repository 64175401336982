// Avatars
.avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPnVzZXItcGxhY2Vob2xkZXItaW1nPC90aXRsZT4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJ1c2VyLXBsYWNlaG9sZGVyLWltZyIgZmlsbD0iI0UxRTFFMSIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTUxMiwwIEw1MTIsNTEyIEwwLDUxMiBMMCwwIEw1MTIsMCBaIE0yNTYsMjg1IEMxODEuOTU5LDI4NSAxMjEuOTM4LDMyOC4xNTkgMTIxLjkzOCwzODEuMzk4IEMxMjEuOTM4LDM5Mi43NzYgMTMxLjE2Miw0MDIgMTQyLjU0LDQwMiBMMTQyLjU0LDQwMiBMMzY5LjQ2LDQwMiBDMzgwLjgzOSw0MDIgMzkwLjA2MiwzOTIuNzc2IDM5MC4wNjIsMzgxLjM5OCBDMzkwLjA2MiwzMjguMTU5IDMzMC4wNDEsMjg1IDI1NiwyODUgWiBNMjU2LDkwIEMyMTguOTgsOTAgMTg4Ljk2OSwxMjUuNDY4IDE4OC45NjksMTY5LjIxOSBDMTg4Ljk2OSwyMTIuOTcgMjE4Ljk4LDI0OC40MzggMjU2LDI0OC40MzggQzI5My4wMiwyNDguNDM4IDMyMy4wMzEsMjEyLjk3IDMyMy4wMzEsMTY5LjIxOSBDMzIzLjAzMSwxMjUuNDY4IDI5My4wMiw5MCAyNTYsOTAgWiIgaWQ9IlNoYXBlIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@each $name, $value in $avatar-sizes {
  .avatar-#{$name} {
    @extend .avatar;
    width: #{$value};
    height: #{$value};
    overflow: hidden;
  }
}

// 1:1 Square Avatar
.avatar-square {
  display: inline-block;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  & > img,
  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}