
// :root {
// 	//////////////////
// 	// for Light Theme



// 	/////////////////
// 	// for Dark Theme
// 	&[data-theme="dark"] {
// 		.card {
// 			border-color: var(--global-border-color);
// 		}
// 	}
// }

.card {
	// background-color: var(--white);
	margin-bottom: size(30);
	// border: none;
	// border-color: var(--global-border-color);
	letter-spacing: size(0.5);
	border-radius: size(15);
	// @include css3-prefix(transition, all 0.3s ease);
	@include css3-prefix(transition, none);
	// @include css3-prefix(box-shadow, 0 size(4) size(30) rgb(95 94 231 / 7%));

	@include media-breakpoint-down(md) {
		border-radius: size(8);
	}

	// for Card size .md
	// &.card-md {
	// 	max-width: 600px;
	// 	margin: auto;
	// 	margin-block-end: size(30);
	// }

	// Card Header
	.card-header {
		// background-color: var(--white);
		padding: size(20);
		border-bottom: none;
		border-top-left-radius: size(15);
		border-top-right-radius: size(15);
		position: relative;

		@include media-breakpoint-down(md) {
			padding: size(16);
			border-top-left-radius: size(8);
			border-top-right-radius: size(8);
		}
	}

	// Card Body
	& > .card-body {
		&:first-child {
			border-top-left-radius: size(15);
			border-top-right-radius: size(15);
	
			@include media-breakpoint-down(md) {
				border-top-left-radius: size(8);
				border-top-right-radius: size(8);
			}
		}
	}

	.card-body {
		padding: size(20);
		// background-color: var(--white);

		@include media-breakpoint-down(md) {
			padding: size(16);
		}

		// &:not(~ .card-footer) {
		// 	border-bottom-left-radius: size(15);
		// 	border-bottom-right-radius: size(15);

		// 	@include media-breakpoint-down(md) {
		// 		border-bottom-left-radius: size(8);
		// 		border-bottom-right-radius: size(8);
		// 	}
		// }

		&:last-child {
			border-bottom-left-radius: size(15);
			border-bottom-right-radius: size(15);

			@include media-breakpoint-down(md) {
				border-bottom-left-radius: size(8);
				border-bottom-right-radius: size(8);
			}
		}
	}

	// Card Footer
	.card-footer {
		padding: size(20);
		// background-color: var(--white);
		// border-top: size(1) solid var(--global-border-color);
		border-bottom-left-radius: size(15);
		border-bottom-right-radius: size(15);
		@include css3-prefix(transition, border 0.3s ease);

		@include media-breakpoint-down(md) {
			padding: size(16);
			border-bottom-left-radius: size(8);
			border-bottom-right-radius: size(8);
		}
	}
}

// Card Title
.card-title {
	font-size: size(22);
	line-height: size(26);
	font-weight: $fw-semibold;
	@include media-breakpoint-down(xxxl) {
		font-size: size(20);
	}
}

// Card Subtitle
.card-subtitle {
	font-size: size(18);
	line-height: size(22);
	font-weight: $fw-regular;
}

// for Read More Link in Footer
.card-read-more-link {
	font-size: size(14);
	font-weight: $fw-regular;
}
