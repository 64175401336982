
.input-tags-container {
  display: flex;
  justify-content: flex-start;
}

.selected-tag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 5px;
  border: 1px solid rgb(83, 152, 242);
  border-radius: 5px;
  background-color: rgb(83, 152, 242);
  white-space: nowrap;
  color: white;
}

.selected-tag button {
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: white;
}
