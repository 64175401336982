
// Common Style
.form-check-input {

  &::before {
    color: inherit;
    z-index: 1;
    pointer-events: none;
  }

  // if Checked
  &:checked {
    background-color: transparent;

    &:disabled {
      filter: opacity(0.7) grayscale(1);
      cursor: not-allowed;

      & ~ label {
        filter: opacity(0.7) grayscale(1);
        cursor: not-allowed;
      }
    }
  }

  // if Not Checked
  &:not(:checked) {
    &:disabled {
      filter: opacity(0.7) grayscale(1);
      cursor: not-allowed;

      & ~ label {
        filter: opacity(0.7) grayscale(1);
        cursor: not-allowed;
      }
    }
  }

  // for Remove Focus
  &:focus,
  &:focus-visible {
    border-color: inherit;
    outline: 0;
    outline-offset: 0;
    box-shadow: none;
  }

  // for Remove style of React BS
  &:active {
    filter: none;
  }

  // & ~ label {
  //   &:not(.visually-hidden) {
  //     padding-inline-start: size(4);
  //     margin-bottom: 0;
  //     line-height: 1;
  //     @include css3-prefix (transition, all 0.2s ease);
  //     cursor: pointer;
  //   }
  // }

}

// for Input Group
.input-group {
  .btn {
    border-width: size(1);
    border-style: solid;
    border-color: var(--input-border-color);
    z-index: unset;
  }
}

// for Password
.password-block {
  .form-control {
    border-right: 0;
  }

  & > button {
    background: transparent;
    border-color: var(--input-border-color);

    &:hover,
    &:active,
    &:focus {
      color: $white;
      background: transparent;
      border-color: var(--input-border-color);
    }
  }
}

// for input label to be aligned center
.form-check-label {
  align-self: center;
}
