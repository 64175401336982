
.checkbox-in {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &~label {
    display: flex;
    align-items: center;
    background: mix($primary, white, 5%);
    border-color: mix($primary, white, 5%);
    font-size: size(12);
    color: $primary;

    &:not(.btn-check) {
      &:hover {
        background: mix($primary, white, 5%);
        border-color: mix($primary, white, 5%);
        color: $primary;
      }
    }

    &:not(.dropdown-toggle) {
      &:not(:disabled) {
        &:before {
          content: "";
          position: static;
          width: size(16);
          height: size(16);
          transform: translate(0, 0) scale(1);
          margin-right: size(12);
          border-radius: 4px;
          border: 1px solid $primary;
          background: transparent;
        }
      }
    }

    &:after {
      content: "";
      display: inline-block;
      width: size(9);
      height: size(5);
      border-width: 0 0 0.125rem 0.125rem;
      border-style: solid;
      border-color: currentColor;
      position: absolute;
      top: size(11);
      left: size(16);
      transform: rotateZ(-45deg) scale(1);
      -webkit-transition: all 0.15s ease;
      transition: all 0.15s ease;
      opacity: 0;
    }
  }

  &:checked {
    &+label {
      background-color: mix($success, white, 25%);
      border-color: mix($success, white, 25%);
      color: mix($success, black, 70%);

      &:hover {
        background-color: mix($success, white, 25%);
        border-color: mix($success, white, 25%);
        color: mix($success, black, 70%);
      }

      &::after {
        transform: rotateZ(-45deg) scale(1);
        opacity: 1;
      }

      &:not(.dropdown-toggle) {
        &:not(:disabled) {
          &:before {
            border-color: mix($success, black, 70%);
          }
        }
      }
    }
  }
}