
@each $name, $value in $borders {
  .border-#{$name} {
    border-top: $value solid var(--input-border-color) !important;
    border-right: $value solid var(--input-border-color) !important;
    border-bottom: $value solid var(--input-border-color) !important;
    border-left: $value solid var(--input-border-color) !important;
  }
  .border-top-#{$name} {
    border-top: $value solid var(--input-border-color) !important;
  }
  .border-end-#{$name} {
    border-right: $value solid var(--input-border-color) !important;
  }
  .border-bottom-#{$name} {
    border-bottom: $value solid var(--input-border-color) !important;
  }
  .border-start-#{$name} {
    border-left: $value solid var(--input-border-color) !important;
  }
  .border-y-#{$name} {
    border-top: $value solid var(--input-border-color) !important;
    border-bottom: $value solid var(--input-border-color) !important;
  }
  .border-x-#{$name} {
    border-left: $value solid var(--input-border-color) !important;
    border-right: $value solid var(--input-border-color) !important;
  }
}
