

// Global Style
:root {
  //////////////////
  // for Light Theme



  /////////////////
  // for Dark Theme
  &[data-theme="dark"] {
    .filter-box {

      .card {
        background-color: rgba(#000000, 0.25);
      }

      // Tags
      .tags {
        border-color: rgba(#FFFFFF, 0.5);
        color: rgba(#FFFFFF, 0.5);
      }
    }
  }
}

.filter-box {
  display: none;
  // overflow: hidden;
  @include css3-prefix(transition, display 0.3s ease);

  & > * {
    height: 0;
    @include css3-prefix(transition, height 1s ease);
  }

  &.show {
    display: block;

    & > * {
      height: auto;
    }
  }

  .flBox {
    --flBox-left-width: 65%;

    background-color: rgba($black, 0.02);
    border-radius: size(15);
    // overflow: hidden;
    margin-top: size(16);

    &-body {
      display: flex;
      background-color: inherit;
      padding: size(20);

      @include media-breakpoint-down(xl) {
        flex-direction: column-reverse;
        gap: size(20);
      }
    }

    .flBox-left {
      // @include media-breakpoint-up(sm) {
      //   width: 50%;
      //   border-inline-end: size(1) solid rgba($black, 0.05);
      //   padding-inline-end: size(12);
      // }
      @include media-breakpoint-up(xl) {
        border-inline-end: size(1) solid rgba($black, 0.05);
        padding-inline-end: size(12);
        width: var(--flBox-left-width);
      }
      @include media-breakpoint-down(xl) {
        width: 100%;
        border-inline-end: none;
        padding-inline-end: 0;
      }
    }
    .flBox-right {
      // @include media-breakpoint-up(sm) {
      //   width: 50%;
      //   padding-inline-start: size(12);
      // }
      @include media-breakpoint-up(xl) {
        width: calc(100% - var(--flBox-left-width));
        padding-inline-start: size(12);
      }
      @include media-breakpoint-down(xl) {
        width: 100%;
        border-bottom: size(1) solid rgba($black, 0.05);
        padding-bottom: size(12);
        padding-inline-start: 0;
      }
    }
  }

  .card {
    background-color: rgba(#000000, 0.02);
    border: 0;

    .card-head,
    .card-body,
    .card-footer {
      background-color: inherit;
      border: 0;
    }
  }

  // Tags
  .tags {
    border-color: rgba(#000000, 0.5);
    color: rgba(#000000, 0.5);

    &:hover {
      .inic {
        display: inline-block;
      }
    }

    .inic {
      display: none;
      margin-inline-start: size(6);
      cursor: pointer;
      @include css3-prefix(transition, display 0.35s ease);
    }
  }
}

// for Filter Type Listing
.ft-listing {
  @include list-unstyled;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > li {
    &:not(:first-child) {
      margin-inline-start: size(6);
    }
  }
}

.filter-checkbox {
  display: inline-block;
  margin: 0;
  line-height: 1;
  cursor: pointer;

  & > span {
    display: inline-block;
    padding: size(6) size(10);
    border-width: size(1);
    border-style: solid;
    border-color: $primary;
    border-radius: size(4);
    font-size: size(12);
    line-height: size(14);
    font-weight: $fw-medium;
    color: $primary;
    @include css3-prefix(transition, all 0.35s ease);

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  [type="checkbox"],
  [type="radio"] {
    display: none;
    appearance: none;

    &:checked {
      & ~ span {
        background-color: $primary;
        color: $white;
      }
    }
  }
}


// Saved Filters
.saved-ft {
  @include list-unstyled;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > li {
    margin-inline-end: size(12);
    margin-bottom: size(6);
  }
}

// Filter with checkbox
.filter-dropdown {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    min-width: 470px;
    padding: 15px 20px;
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
    border-radius: 12px;
    z-index: 1052;
  }
  .large-filter {
    max-height: 400px;
    overflow: auto;
  }
}