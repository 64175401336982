
// Date Picker Block
// .datepicker-block {
// 	position: relative;
// 	&:after {
// 		content: "\ea15";
// 		position: absolute;
// 		top: 50%;
// 		right: size(10);
// 		transform: translateY(-50%);
// 		font-family: $ff-icons;
// 		font-size: size(16);
// 		color: $gray-500;
// 		pointer-events: none;
// 	}

// 	& > input {
// 		padding-inline-end: size(30);
// 		border-radius: size(4);

// 		&::-webkit-inner-spin-button,
// 		&::-webkit-calendar-picker-indicator {
// 			opacity: 0;
// 			@include css3-prefix(appearance, none);
// 		}		
// 	}
// }


// Time Picker Block
// .timepicker-block {
// 	position: relative;
// 	&:after {
// 		content: "\ec45";
// 		position: absolute;
// 		top: 50%;
// 		right: size(10);
// 		transform: translateY(-50%);
// 		font-family: $ff-icons;
// 		font-size: size(16);
// 		color: $gray-500;
// 		pointer-events: none;
// 	}

// 	& > input {
// 		border-radius: size(4);

// 		&::-webkit-inner-spin-button,
// 		&::-webkit-calendar-picker-indicator {
// 			opacity: 0;
// 			@include css3-prefix(appearance, none);
// 		}
// 	}
// }

// Date and Time Picker Block
// .date-time-picker-block {
// 	position: relative;
// 	&:after {
// 		content: "\ea15";
// 		position: absolute;
// 		top: 50%;
// 		right: size(10);
// 		transform: translateY(-50%);
// 		font-family: $ff-icons;
// 		font-size: size(16);
// 		color: $gray-500;
// 		pointer-events: none;
// 	}

// 	& > input {
// 		border-radius: size(4);

// 		&::-webkit-inner-spin-button,
// 		&::-webkit-calendar-picker-indicator {
// 			opacity: 0;
// 			@include css3-prefix(appearance, none);
// 		}
// 	}
// }


// 
.input-daterange {
	input {
		text-align: left;
	}
}
