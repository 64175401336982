
// Rating
.my-rating {
	display: inline-flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	font-size: $font-size-base * 1.5;
	color: $primary;
	border: none;
	white-space: nowrap;

	&:not(:checked) {

		label,
		.rating-icons {
			&:hover {

				& ~ label,
				& ~ .rating-icons {
					.checked-icon {
						display: block;
					}

					.unchecked-icon {
						display: none;
					}
				}
			}
		}
	}

	&.disabled {

		label,
		input[type="radio"] {
			pointer-events: none;
		}
	}

	input[type="radio"] {
		&:disabled {

			& ~ label,
			& ~ .rating-icons {
				pointer-events: none;
			}
		}
	}

	input[type="radio"] {
		display: none;

		&:checked {

			& ~ label,
			& ~ .rating-icons {
				.checked-icon {
					display: block;
				}

				.unchecked-icon {
					display: none;
				}
			}
		}
	}

	label,
	.rating-icons {
		font-size: inherit;
		color: inherit;
		cursor: pointer;
		padding: 0;
		margin: 0;

		.checked-icon,
		.unchecked-icon {
			font-size: inherit;
			line-height: 1;
			color: inherit;
		}

		.checked-icon {
			display: none;
		}

		.unchecked-icon {
			display: block;
			filter: grayscale(0.25);
		}

		&:hover {
			.checked-icon {
				display: block;
			}

			.unchecked-icon {
				display: none;
			}
		}
	}

	&.disable {

		label,
		.rating-icons {
			pointer-events: none;
		}
	}
}