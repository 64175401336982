
// Button
.btn {
	display: inline-flex;
	align-items: center;
	font-weight: $fw-regular;
	position: relative;
	overflow: hidden;
	// @include media-breakpoint-down(xxxl) {
	// 	font-size: size(12);
	// }

	// for Icon
	[class*="inic"],
	[class*="bx"] {
		font-size: size(18);
		line-height: size(18);
	}
	&-outline-dark {
		border-color: var(--dark);
		color: var(--dark);
	}
	&-outline-primary {
		&:hover, &:active, &:focus:not(:focus-visible) {
			color: var(--white);
			background-color: var(--primary);
		}
	}
	&-primary {
		color: var(--white);
		&:hover, &:active, &:focus:not(:focus-visible) {
			color: var(--white);
		}
	}
	&-success {
		color: $white;
		&:hover, &:active, &:focus:not(:focus-visible) {
			color: $white;
		}
	}

	// for Icon + Text
	&.btn-icon-text {
		[class*="inic"],
		[class*="bx"] {
			margin-inline-end: size(10);
		}
	}

	// for Text + Icon
	&.btn-text-icon {
		[class*="inic"],
		[class*="bx"] {
			margin-inline-start: size(10);
		}
	}

	// for Icon Only
	&.btn-only-icon {
		padding: size(9);
	}

	// Button - Small
	&.btn-sm {
		border-radius: size(4);

		// for Icon
		[class*="inic"],
		[class*="bx"] {
			font-size: size(17);
			line-height: 1;
		}

		// for Icon + Text
		&.btn-icon-text {
			[class*="inic"],
			[class*="bx"] {
				margin-inline-end: size(6);
			}
		}

		// for Text + Icon
		&.btn-text-icon {
			[class*="inic"],
			[class*="bx"] {
				margin-inline-start: size(6);
			}
		}

		// for Icon Only
		&.btn-only-icon {
			padding: size(6);
		}
	}

	// Button - Large
	&.btn-lg {

		// for Icon
		[class*="inic"],
		[class*="bx"] {
			font-size: size(20);
		}

		// for Icon + Text
		&.btn-icon-text {
			[class*="inic"],
			[class*="bx"] {
				margin-inline-end: size(10);
			}
		}

		// for Text + Icon
		&.btn-text-icon {
			[class*="inic"],
			[class*="bx"] {
				margin-inline-start: size(10);
			}
		}

		// for Icon Only
		&.btn-only-icon {
			padding: size(14) size(13);
		}
	}

	// for Icon
	[class*="inic"],
	[class*="bx"] {
		color: inherit;
	}

	// for Hover, Focus
	&:hover,
	&:focus {
		// for Icon
		[class*="inic"],
		[class*="bx"] {
			color: inherit;
		}
	}

	// for Ripple Effect
	&:not(.dropdown-toggle) {
		&:not(:disabled) {
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(0);
				width: size(500);
				height: size(500);
				border-radius: 50%;
				background-color: rgba(255, 255, 255, 0.8);
			}
		}

		&:focus,
		&:active {
			&:before {
				transform: translate(-50%, -50%) scale(1);
				opacity: 0;
				@include css3-prefix(transition, all 1s ease);
			}
		}
	}

	// for Remove default styling
	&:hover,
	&:active,
	&:focus,
	&:focus-visible {
		box-shadow: none;
		text-decoration: none;
		outline: none;
	}

	&:active {
		&:focus {
			box-shadow: none;
		}
	}

	&.viewActive {
		color: var(--bs-btn-hover-color);
		background-color: var(--bs-btn-hover-bg);
		border-color: var(--bs-btn-hover-border-color);
	}
}

// Button wrapper
.btn-wrapper {
	padding: size(12) size(15);
}

// Button Close
.btn-close {
	&:focus {
		box-shadow: none;
	}
}


// Button - Ghost
@each $color, $value in $theme-colors {
	.btn-ghost-#{$color} {
		color: $value;
		background: transparent;
		border-color: transparent;

		&.show, // for Dropdown Toggle
		&:hover,
		&:active,
		&.active,
		&:focus,
		&:focus-visible {
			color: $value !important;
			background-color: rgba($value, 0.15) !important;
			border-color: transparent !important;
		}
	}
}


// for Fill Buttons - Lighter Versions
@each $color, $value in $theme-colors {
  .btn-#{$color}-light {
		// background-color: mix($value, white, 25%);
		// border-color: mix($value, white, 25%);
		// color: mix($value, black, 70%);
		background-color: rgba($value, 20%);
		border-color: transparent;
		color: $value;

		&:hover,
		&:focus,
		&.focus,
		&.active,
		&.show,
		&:focus-visible {
			// background-color: mix($value, white, 35%) !important;
			// border-color: mix($value, white, 35%) !important;
			// color: mix($value, black, 70%) !important;
			background-color: rgba($value, 50%) !important;
			border-color: transparent;
			color: mix($value, black, 25%) !important;
		}

		&:first-child {
			&:active {
				background-color: rgba($value, 50%) !important;
				border-color: transparent;
				color: mix($value, black, 25%) !important;
			}
		}
	}
}
