

// for CSS Variables of Light & Dark Themes ~~~
:root {

  //////////////////
  // for Light Theme
  .global-header {
    background-color: $white;
  }

  /////////////////
  // for Dark Theme
  &[data-theme="dark"] {
    .global-header {
      background-color: #252525;
      border-bottom-color: #1E1E1E;
    }
  }
}

// NEW SCSS FOR HEADER STARTS HERE
.global-header {
  display: flex;
  justify-content: space-between;
  // background: var(--white);
  border-bottom: size(1) solid var(--global-border-color);
  padding: size(13) size(30);

  // @include media-breakpoint-up(lg) {
  @media screen and (min-width: 1367px) {
    width: calc(100% - size(224));
    margin-inline-start: auto;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0;
    z-index: 1031;
    @include css3-prefix(transition, width 0.35s ease-in-out);
  }

  // @include media-breakpoint-down(lg) {
  @media screen and (max-width: 1366px) {
    width: 100%;
    padding-inline: size(15);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1044;
  }

  .toggle-sidebar {
    align-self: center;

    & > a {
      display: grid;
      place-items: center;
      padding: size(6);
      font-size: size(20);
      line-height: 1;
      border-radius: 100%;
      @include css3-prefix(transition, background-color 0.35s ease-in-out);
    }
  }

  .nav-right {
    .nav-menus {
      list-style: none;
      display: inline-flex;
      padding: 0;
      margin-bottom: 0;

      .nav-link {
        display: grid;
        place-items: center;
        padding: size(6);
        margin-inline-start: size(6);
        border-radius: 100%;
        font-size: size(20);
        line-height: 1;
        @include css3-prefix(transition, background-color 0.35s ease-in-out);
      }
    }
  }
}

// Sidebar Collapsed ~~~~~
.sidebar-collapsed {
  .global-header {
    @include css3-prefix(transition, width 0.35s ease-in-out);

    // @include media-breakpoint-up(lg) {
    @media screen and (min-width: 1367px) {
      width: calc(100% - size(60));
    }
  }

  .toggle-sidebar {
    [class*="inic"] {
      transform: rotateY(180deg);
    }
  }
}

// Notifications Dropdown Menu ~~~~~
.new-notification {
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: size(5);
    height: size(5);
    background: $danger;
    border-radius: 100%;
    position: absolute;
    top: size(6);
    right: size(6);
    z-index: 1;
  }
}
