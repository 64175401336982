// For Bootstrap
.form-label,
// for React Bootstrap
.form-check-label {
  display: inline-flex;
  font-size: size(14);
  line-height: 1.25;
  font-weight: $fw-regular;
  color: var(--black);
  margin-bottom: size(4);
}

.form-control-info {
  display: inline-block;
  font-size: size(10);
  line-height: 1.125;
  font-weight: $fw-regular;
  color: var(--black);
  margin: 0;
}


// for Anchor Hover
.anchor-hover {
  color: currentColor;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    color: inherit;
    border-bottom: size(1) solid currentColor;
    @include css3-prefix(transition, width 0.35s ease);
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

// for Letter Spacing
.ls-normal {
  letter-spacing: normal;
}

// for User and Profile view
.form-content {
  @include font(14, 22);
}
