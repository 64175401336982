

// for react tel input
.form-control-tel{
  .form-control {
    width: 100%;
    height: unset;
    padding: size(6) size(12) size(6) size(48);
    background: transparent;
    border: size(1) solid var(--input-border-color);
    border-radius: size(6);
    font-size: size(16);
    line-height: 1.5;
    font-weight: $fw-regular;
  }

  .flag-dropdown {
    background-color: transparent;
    border: 0;
    top: 3px;
    bottom: 3px;
    left: 2px;

    .selected-flag{
      background: transparent;
    }
    &.open {
      background: transparent;
      .selected-flag {
        background: transparent;
      }
    }
  }
  .country-list {
    background-color: var(--white);
    .search {
      padding: 10px;
      background-color: var(--white);
      .search-box {
        @extend .form-control;
        width: 100%;
        margin: 0;
        padding-left: 38px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center left 12px;
        background-size: 14px 14px;
      }
    }
    .country {
      &:hover,
      &.highlight {
        background-color: rgba($color: $primary, $alpha: .1);
      }
    }
  }
}
