// Global Styles ~~~
:root {
	//////////////////
	// for Light Theme
	.form-control {
		// for Disabled
		&:disabled {
			background: rgba(0, 0, 0, 0.07);
		}

		// for Type Search
		&[type="search"] {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NTEgMTEuMDY4MUMzLjM5NTEgNi44MzA0MyA2LjgzMDQzIDMuMzk1MSAxMS4wNjgxIDMuMzk1MUMxNS4zMDU4IDMuMzk1MSAxOC43NDEyIDYuODMwNDMgMTguNzQxMiAxMS4wNjgxQzE4Ljc0MTIgMTUuMzA1OCAxNS4zMDU4IDE4Ljc0MTIgMTEuMDY4MSAxOC43NDEyQzYuODMwNDMgMTguNzQxMiAzLjM5NTEgMTUuMzA1OCAzLjM5NTEgMTEuMDY4MVpNMTEuMDY4MSAyQzYuMDU5OTQgMiAyIDYuMDU5OTQgMiAxMS4wNjgxQzIgMTYuMDc2MyA2LjA1OTk0IDIwLjEzNjMgMTEuMDY4MSAyMC4xMzYzQzE2LjA3NjMgMjAuMTM2MyAyMC4xMzYzIDE2LjA3NjMgMjAuMTM2MyAxMS4wNjgxQzIwLjEzNjMgNi4wNTk5NCAxNi4wNzYzIDIgMTEuMDY4MSAyWk0xOC4wMTUxIDE4LjM3MjhDMTguNDA4IDE3LjgyNzQgMTkuMDUyIDE3LjU0NTEgMTkuNzY3NiAxNy41NTA1QzIwLjYxNTEgMTcuNTUxMiAyMS40MTM3IDE3Ljg0NDkgMjEuNzk0NCAxOC41NTU4QzIyLjE3NTQgMTkuMjY3MiAyMS45NzY4IDIwLjA5NTYgMjEuNTA4MyAyMC44MDFMMjEuNTA4MyAyMC44MDExQzIxLjAxNTIgMjEuNTQzMyAyMC4yOTU1IDIyLjA5ODIgMTkuNDUzNCAyMS45ODU0QzE4LjYxMTIgMjEuODcyNSAxOC4wNjI2IDIxLjE0NzQgMTcuNzgxOCAyMC4yOTk2TDE3Ljc4MTYgMjAuMjk5QzE3LjU1NzEgMTkuNjE5MiAxNy42MjI2IDE4LjkxNzggMTguMDE1MSAxOC4zNzI4Wk0xOS42Mzg3IDIwLjYwMjZDMTkuNTc4OCAyMC41OTQ2IDE5LjMxODMgMjAuNTAxMyAxOS4xMDYyIDE5Ljg2MTJMMjAuMzQ2MSAyMC4wMjkyQzE5Ljk3NDIgMjAuNTg5IDE5LjY5ODggMjAuNjEwNyAxOS42Mzg3IDIwLjYwMjZaTTE5LjEwNjIgMTkuODYxMkwyMC4zNDYyIDIwLjAyOTFDMjAuNjY4MyAxOS41NDQxIDIwLjYwNDUgMTkuMjg5IDIwLjU2NDYgMTkuMjE0NEMyMC41MjQ5IDE5LjE0MDMgMjAuMzQ4NCAxOC45NDU2IDE5Ljc2NDcgMTguOTQ1NkgxOS43NTg1VjE4Ljk0NTZDMTkuNDE1MiAxOC45NDI2IDE5LjIzMjcgMTkuMDY5NCAxOS4xNDcyIDE5LjE4ODJDMTkuMDYwNyAxOS4zMDgyIDE4Ljk5NjEgMTkuNTI3NCAxOS4xMDYyIDE5Ljg2MTJaIiBmaWxsPSIjMTQxODFBIi8+Cjwvc3ZnPgo=");
		}
	}

	/////////////////
	// for Dark Theme
	&[data-theme="dark"] {
		.form-control {
			// for Disabled
			&:disabled {
				background: rgba(255, 255, 255, 0.2);
			}

			// for Type Search
			&[type="search"] {
				background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NTEgMTEuMDY4MUMzLjM5NTEgNi44MzA0MyA2LjgzMDQzIDMuMzk1MSAxMS4wNjgxIDMuMzk1MUMxNS4zMDU4IDMuMzk1MSAxOC43NDEyIDYuODMwNDMgMTguNzQxMiAxMS4wNjgxQzE4Ljc0MTIgMTUuMzA1OCAxNS4zMDU4IDE4Ljc0MTIgMTEuMDY4MSAxOC43NDEyQzYuODMwNDMgMTguNzQxMiAzLjM5NTEgMTUuMzA1OCAzLjM5NTEgMTEuMDY4MVpNMTEuMDY4MSAyQzYuMDU5OTQgMiAyIDYuMDU5OTQgMiAxMS4wNjgxQzIgMTYuMDc2MyA2LjA1OTk0IDIwLjEzNjMgMTEuMDY4MSAyMC4xMzYzQzE2LjA3NjMgMjAuMTM2MyAyMC4xMzYzIDE2LjA3NjMgMjAuMTM2MyAxMS4wNjgxQzIwLjEzNjMgNi4wNTk5NCAxNi4wNzYzIDIgMTEuMDY4MSAyWk0xOC4wMTUxIDE4LjM3MjhDMTguNDA4IDE3LjgyNzQgMTkuMDUyIDE3LjU0NTEgMTkuNzY3NiAxNy41NTA1QzIwLjYxNTEgMTcuNTUxMiAyMS40MTM3IDE3Ljg0NDkgMjEuNzk0NCAxOC41NTU4QzIyLjE3NTQgMTkuMjY3MiAyMS45NzY4IDIwLjA5NTYgMjEuNTA4MyAyMC44MDFMMjEuNTA4MyAyMC44MDExQzIxLjAxNTIgMjEuNTQzMyAyMC4yOTU1IDIyLjA5ODIgMTkuNDUzNCAyMS45ODU0QzE4LjYxMTIgMjEuODcyNSAxOC4wNjI2IDIxLjE0NzQgMTcuNzgxOCAyMC4yOTk2TDE3Ljc4MTYgMjAuMjk5QzE3LjU1NzEgMTkuNjE5MiAxNy42MjI2IDE4LjkxNzggMTguMDE1MSAxOC4zNzI4Wk0xOS42Mzg3IDIwLjYwMjZDMTkuNTc4OCAyMC41OTQ2IDE5LjMxODMgMjAuNTAxMyAxOS4xMDYyIDE5Ljg2MTJMMjAuMzQ2MSAyMC4wMjkyQzE5Ljk3NDIgMjAuNTg5IDE5LjY5ODggMjAuNjEwNyAxOS42Mzg3IDIwLjYwMjZaTTE5LjEwNjIgMTkuODYxMkwyMC4zNDYyIDIwLjAyOTFDMjAuNjY4MyAxOS41NDQxIDIwLjYwNDUgMTkuMjg5IDIwLjU2NDYgMTkuMjE0NEMyMC41MjQ5IDE5LjE0MDMgMjAuMzQ4NCAxOC45NDU2IDE5Ljc2NDcgMTguOTQ1NkgxOS43NTg1VjE4Ljk0NTZDMTkuNDE1MiAxOC45NDI2IDE5LjIzMjcgMTkuMDY5NCAxOS4xNDcyIDE5LjE4ODJDMTkuMDYwNyAxOS4zMDgyIDE4Ljk5NjEgMTkuNTI3NCAxOS4xMDYyIDE5Ljg2MTJaIiBmaWxsPSIjNTA1MDUwIi8+Cjwvc3ZnPgo=");
			}
		}
	}
}

// for remove default style when input autofill
input:-webkit-autofill,
input:-internal-autofill-selected,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	background-image: none !important;
	background-color: transparent !important;
	-webkit-box-shadow: 0 0 0 50px white inset !important;
}

// Form Control
.form-control {
	background-color: transparent;
	border-color: var(--input-border-color);
	color: var(--black);
	box-shadow: none;
	border-radius: 6px;
	@include css3-prefix(transition, none);

	// for Placeholder
	@include placeholder {
		color: var(--gray);
	}

	// for Focus
	&.focus,
	&:focus {
		background-color: transparent;
		border-color: var(--input-border-color);
		color: var(--black);
		box-shadow: none;

		@include placeholder {
			color: var(--gray);
		}
	}

	// for Disabled
	&:disabled,
	&[disabled] {
		cursor: not-allowed;
	}

	// for Invalid
	&.is-invalid {
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NTM1IDEyQzMuMzk1MzUgNy4yNDc3OCA3LjI0Nzc4IDMuMzk1MzUgMTIgMy4zOTUzNUMxNi43NTIyIDMuMzk1MzUgMjAuNjA0NyA3LjI0Nzc4IDIwLjYwNDcgMTJDMjAuNjA0NyAxNi43NTIyIDE2Ljc1MjIgMjAuNjA0NyAxMiAyMC42MDQ3QzcuMjQ3NzggMjAuNjA0NyAzLjM5NTM1IDE2Ljc1MjIgMy4zOTUzNSAxMlpNMTIgMkM2LjQ3NzE1IDIgMiA2LjQ3NzE1IDIgMTJDMiAxNy41MjI4IDYuNDc3MTUgMjIgMTIgMjJDMTcuNTIyOCAyMiAyMiAxNy41MjI4IDIyIDEyQzIyIDYuNDc3MTUgMTcuNTIyOCAyIDEyIDJaTTEyLjAyODkgNy4zNDg3NUMxMi40MTQyIDcuMzQ4NzUgMTIuNzI2NSA3LjY2MTExIDEyLjcyNjUgOC4wNDY0M1YxMi45MzAyQzEyLjcyNjUgMTMuMzE1NSAxMi40MTQyIDEzLjYyNzggMTIuMDI4OSAxMy42Mjc4QzExLjY0MzYgMTMuNjI3OCAxMS4zMzEyIDEzLjMxNTUgMTEuMzMxMiAxMi45MzAyVjguMDQ2NDNDMTEuMzMxMiA3LjY2MTExIDExLjY0MzYgNy4zNDg3NSAxMi4wMjg5IDcuMzQ4NzVaTTEyLjAyODkgMTQuOTc2NkMxMi41NDI2IDE0Ljk3NjYgMTIuOTU5MSAxNS4zOTMxIDEyLjk1OTEgMTUuOTA2OFYxNS45OTk5QzEyLjk1OTEgMTYuNTEzNiAxMi41NDI2IDE2LjkzMDEgMTIuMDI4OSAxNi45MzAxQzExLjUxNTEgMTYuOTMwMSAxMS4wOTg2IDE2LjUxMzYgMTEuMDk4NiAxNS45OTk5VjE1LjkwNjhDMTEuMDk4NiAxNS4zOTMxIDExLjUxNTEgMTQuOTc2NiAxMi4wMjg5IDE0Ljk3NjZaIiBmaWxsPSIjRTUyNzI3Ii8+Cjwvc3ZnPgo=");
	}

	// for Readonly
	&[readonly] {
		color: var(--gray);
		cursor: not-allowed;
	}

	// for remove spinner of Input type Number
	&[type="number"]::-webkit-inner-spin-button,
	&[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&[type="number"] {
		-moz-appearance: textfield;
	}

	// for Type Color
	&[type="color"] {
		height: size(38);
	}
}


// for Form Invalid
.was-validated {
	.form-control {
		&:invalid {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NTM1IDEyQzMuMzk1MzUgNy4yNDc3OCA3LjI0Nzc4IDMuMzk1MzUgMTIgMy4zOTUzNUMxNi43NTIyIDMuMzk1MzUgMjAuNjA0NyA3LjI0Nzc4IDIwLjYwNDcgMTJDMjAuNjA0NyAxNi43NTIyIDE2Ljc1MjIgMjAuNjA0NyAxMiAyMC42MDQ3QzcuMjQ3NzggMjAuNjA0NyAzLjM5NTM1IDE2Ljc1MjIgMy4zOTUzNSAxMlpNMTIgMkM2LjQ3NzE1IDIgMiA2LjQ3NzE1IDIgMTJDMiAxNy41MjI4IDYuNDc3MTUgMjIgMTIgMjJDMTcuNTIyOCAyMiAyMiAxNy41MjI4IDIyIDEyQzIyIDYuNDc3MTUgMTcuNTIyOCAyIDEyIDJaTTEyLjAyODkgNy4zNDg3NUMxMi40MTQyIDcuMzQ4NzUgMTIuNzI2NSA3LjY2MTExIDEyLjcyNjUgOC4wNDY0M1YxMi45MzAyQzEyLjcyNjUgMTMuMzE1NSAxMi40MTQyIDEzLjYyNzggMTIuMDI4OSAxMy42Mjc4QzExLjY0MzYgMTMuNjI3OCAxMS4zMzEyIDEzLjMxNTUgMTEuMzMxMiAxMi45MzAyVjguMDQ2NDNDMTEuMzMxMiA3LjY2MTExIDExLjY0MzYgNy4zNDg3NSAxMi4wMjg5IDcuMzQ4NzVaTTEyLjAyODkgMTQuOTc2NkMxMi41NDI2IDE0Ljk3NjYgMTIuOTU5MSAxNS4zOTMxIDEyLjk1OTEgMTUuOTA2OFYxNS45OTk5QzEyLjk1OTEgMTYuNTEzNiAxMi41NDI2IDE2LjkzMDEgMTIuMDI4OSAxNi45MzAxQzExLjUxNTEgMTYuOTMwMSAxMS4wOTg2IDE2LjUxMzYgMTEuMDk4NiAxNS45OTk5VjE1LjkwNjhDMTEuMDk4NiAxNS4zOTMxIDExLjUxNTEgMTQuOTc2NiAxMi4wMjg5IDE0Ljk3NjZaIiBmaWxsPSIjRTUyNzI3Ii8+Cjwvc3ZnPgo=");
		}
	}
}

// for Input Search
[type="search"] {
	background-repeat: no-repeat;
	background-size: size(16);
	background-position-y: center;

	&:not(.search-icon-left) {
		padding-inline-end: size(32);
		background-position-x: calc(100% - size(10));
	}

	&.search-icon-left {
		padding-inline-start: size(32);
		background-position-x: size(10);
	}
}

// for Form Control File
.form-control-file {
	display: inline-block;
	width: 100%;
	min-height: 38px;
	font-size: size(16);
	font-weight: $fw-regular;
	line-height: 1;
	color: var(--dark);
	padding: 6px 12px;
	background: var(--white);
	box-shadow: none;
	border-width: size(1);
	border-style: solid;
	border-color: var(--input-border-color);
	border-radius: size(6);
	appearance: none;
	position: relative;

	[type="file"] {
		visibility: hidden;
		opacity: 0;
		width: 0;
		height: 0;
		border: 0;
	}

	label {
		position: absolute;
		top: size(-1);
		bottom: size(-1);
		display: grid;
		place-items: center;
		padding: size(2) size(12);
		background-color: $primary;
		font-size: size(18);
		line-height: 1;
		color: var(--white);
		cursor: pointer;
	}

	&.fcf-type-left {
		padding-inline-start: size(50);

		label {
			left: size(-1);
			border-top-left-radius: size(6);
			border-bottom-left-radius: size(6);
		}
	}

	&.fcf-type-right {
		padding-inline-end: size(50);

		label {
			right: size(-1);
			border-top-right-radius: size(6);
			border-bottom-right-radius: size(6);
		}
	}
	&.attach-files{
		min-height: auto;
	}
	.form-control-file-name {
		line-height: 1.5;
	}
}
