
// for CSS Variables of Light & Dark Themes ~~~
:root {
  // Ticket User DP dimensions
  --udp-width: 32px;

  //////////////////
  // for Light Theme


  /////////////////
  // for Dark Theme
  &[data-theme="dark"] {
    .cfi {
      input {
        &[type="file"] {
          border-color: var(global-border-color);

          &::file-selector-button {
            background-color: rgba($white, 0.15);
          }
        }
      }
    }

  }
}


// START : View Ticket Support
.vts {
  display: flex;

  @include media-breakpoint-up(xxl) {
    --child-width: 500px;
  }
  @include media-breakpoint-down(xxl) {
    --child-width: 300px;
  }
  @include media-breakpoint-down(lg) {
    --child-width: 100%;
    flex-direction: column-reverse;
  }

  &_left,
  &_right {
    padding-inline: size(20);
  }

  &_left {
    @include media-breakpoint-up(lg) {
      width: calc(100% - var(--child-width));
    }
    @include media-breakpoint-up(xl) {
      border-inline-end: size(1) solid var(--global-border-color);
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &_right {
    @include media-breakpoint-up(lg) {
      width: var(--child-width);
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      position: relative;
    }
  }
}
// END : View Ticket Support

// START : View Ticket Support - Chat
.vts-chat {
  @include list-unstyled;
  margin-bottom: size(24);

  & > li {
    padding-bottom: size(24);
    margin-bottom: size(24);
    border-bottom: size(1) solid var(--global-border-color);
    // &:not(:only-child) {
    //   &:not(:first-child) {
    //     padding-top: size(24);
    //     margin-top: size(24);
    //     border-top: size(1) solid var(--global-border-color);
    //   }
    // }

    // &:nth-child(1) {
    //   &:not(:only-child) {
    //     .card {
    //       margin-bottom: 0;
    //       border-bottom-left-radius: 0;
    //       border-bottom-right-radius: 0;
    //     }
    //   }
    // }

    // &:nth-child(2) {
    //   .card {
    //     border-top: 0;
    //     border-top-left-radius: 0;
    //     border-top-right-radius: 0;
    //   }
    // }

    // & > .card {
    //   margin-bottom: 0;
    // }
  }
}
// END : View Ticket Support - Chat

// START : View Ticket Chat Box
.vts-cb {
  --avatar-size: 40px;
  --content-padding: 12px;

  &-head {
    &-avatar {
      display: grid;
      place-items: center;
      flex-shrink: 0;
      margin: 0;
      width: var(--avatar-size);
      height: var(--avatar-size);
      border-radius: size(6);
      overflow: hidden;
    }

    &-inner {
      width: calc(100% - var(--avatar-size));
      padding-inline-start: var(--content-padding);
    }
  }

  &-body {
    padding-top: size(16);
    padding-inline-start: calc(var(--avatar-size) + var(--content-padding));

    pre {
      white-space: break-spaces;
    }
  }

  // &--sent {
  //   @include media-breakpoint-up(sm) {
  //     padding-inline-start: calc(var(--avatar-size) + var(--content-padding));
  //   }
  // }
}
// END : View Ticket Chat Box

// START : Custom File Input
.cfi {
  display: inline-flex;
  max-width: size(250);
  margin: 0;

  input {
    &[type="file"] {
      background: transparent;
      padding-inline-start: size(100);
      font-size: size(14);
      position: relative;

      &::file-selector-button {
        top: size(-2);
        bottom: size(-2);
        display: inline-flex;
        align-items: center;
        font-weight: $fw-regular;
        overflow: hidden;
        background-color: #E8E8E8;
        border: 0;
        font-size: size(14);
        line-height: 1;
        color: var(--dark);
        position: absolute;
        inset-inline-start: 0;
        margin: 0;
        transition: none;

        &:hover {
          box-shadow: none;
          text-decoration: none;
          outline: none;
        }
      }
    }
  }
}
// END : Custom File Input

// START : Ticket Details Toggle Input (for Responsive View)
.vts-toggle {
  display: none;

  & ~ .vts_right_top {
    @include media-breakpoint-down(lg) {
      padding-inline-start: size(24);
    }
  }

  & ~ .vts_right_bottom {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  & ~ label {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    @include media-breakpoint-down(lg) {
      position: absolute;
      top: size(4);
      font-size: size(16);
      font-weight: $fw-bold;
      cursor: pointer;
    }
  }

  &:checked {
    & ~ label {
      transform: rotateX(180deg)
    }

    & ~ .vts_right_bottom {
      display: block;
    }
  }
}
// END : Ticket Details Toggle Input (for Responsive View)
