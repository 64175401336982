// ==============
/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

// .modal{
//   .modal-dialog{
//     position: fixed;
//     margin: auto;
//     width: size(320);
//     height: 100%;
//     -webkit-transform: translate3d(0%, 0, 0);
//     -ms-transform: translate3d(0%, 0, 0);
//     -o-transform: translate3d(0%, 0, 0);
//     transform: translate3d(0%, 0, 0);
//   }
//   .modal-content{
//     height: 100%;
//     overflow-y: auto;
//   }
//   .modal-body{
//     padding: size(15) size(15) size(80);
//   }
//   /*Left*/
//   &.left{
//     &.fade{
//       .modal-dialog{
//         left: size(-320);
//         -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
//         -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
//         -o-transition: opacity 0.3s linear, left 0.3s ease-out;
//         transition: opacity 0.3s linear, left 0.3s ease-out;
//       }
//       &.show{
//         .modal-dialog{
//           left: 0;
//         }
//       }
//     }
    
//   }
//   /*Right*/
//   &.right{
//     &.fade{
//       .modal-dialog{
//         right: size(-320);
//         -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
//         -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
//         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
//         transition: opacity 0.3s linear, right 0.3s ease-out;
//       }
//       &.show{
//         .modal-dialog{
//           right: 0;
//         }
//       }
//     }
    
//   }

// }


// /* ----- MODAL STYLE ----- */
.modal-content {
    border: none;
    box-shadow: 0 16px 16px -10px rgba(34,47,62,.15), 0 0 40px 1px rgba(34,47,62,.15);
    border-radius: 10px;
}


// .modal-header {
//   border-bottom-color: var(--light-500);
//   background-color: var(--light-40);
// }

// Sweet modal
.swal2-container {
    &.swal2-backdrop-show {
        background: rgba($color: $white, $alpha: .75);
    }
    &.swal2-center {
        >.swal2-popup {
            box-shadow: 0 16px 16px -10px rgba(34,47,62,.15), 0 0 40px 1px rgba(34,47,62,.15);
            border-radius: 10px;
        }
    }
    .swal2-styled.swal2-cancel {
        @extend .btn;
        @extend .btn-outline-gray;
    }
}