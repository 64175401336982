.dropify-wrapper {
  height: unset;
  padding: size(20);
  background: var(--white);
  border: size(2) dashed var(--primary);
  border-radius: size(8);
  overflow: hidden;

  &:hover {
    background-image: none;
  }

  .dropify-message {
    span {
      &.file-icon {
        font-size: size(20);
        color: var(--dark);

        &::before {
          content: "\ec5f";
          font-family: $ff-box-icons;
          font-size: size(28);
        }
      }
    }
  }
}

// for Remove the text
.dropify-icon-only {
  .file-icon {
    p {
      display: none;
    }
  }
}

.uploaded-img {
  .download-icon {
    position: absolute;
    bottom: 4px;
    right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    background-color: var(--dark);
    color: var(--white);
    cursor: pointer;
    @include css3-prefix(transition, all .3s ease);
    &:hover {
      background-color: var(--success);
    }
  }
}