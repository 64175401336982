

// Global Style
// :root {
//   //////////////////
//   // for Light Theme



//   /////////////////
//   // for Dark Theme
//   &[data-theme="dark"] {}
// }

.saved-ft-cb {
  display: inline-block;
  margin: 0;
  line-height: 1;
  overflow: hidden;
  cursor: pointer;

  & > span {
    display: inline-flex;
    align-items: center;
    padding: size(6) size(10);
    border-width: size(1);
    border-style: solid;
    border-color: $primary;
    border-radius: size(4);
    font-size: size(14);
    line-height: size(16);
    font-weight: $fw-medium;
    color: $primary;
    position: relative;
    @include css3-prefix(transition, all 0.35s ease);

    &:hover {
      background-color: rgba($primary, 0.35);
      border-color: transparent;

      .inic {
        display: grid;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: -4px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 7px;
      height: 7px;
      border-radius: 0;
      background-color: $primary;
      @include css3-prefix(transition, all 0.35s ease);
    }

    .inic {
      display: none;
      place-items: center;
      flex-shrink: 0;
      border-radius: size(4);
      margin-inline-start: size(6);
      font-size: size(14);
      line-height: 1;
      color: $danger;
      cursor: pointer;
      @include css3-prefix(transition, all 0.35s ease);
    }
  }

  [type="checkbox"],
  [type="radio"] {
    display: none;
    appearance: none;

    &:checked {
      & ~ span {
        padding-inline-start: size(20);
        background-color: rgba($primary, 0.35);
        border-color: transparent;
        // color: $white;

        &::before {
          left: size(8);
          border-radius: 100px;
        }

        .inic {
          display: grid;
        }
      }
    }
  }
}
.min-h-auto {
  min-height: auto !important;
}