
// NOTE :
//        - Use the class .rjs-select as className
//        - Use the class .form-select as classNamePrefix
.rjs-select {
  .form-select {
    &__control {
      background-color: transparent;
      border-color: var(--input-border-color);

      &--is-focused,
      &--menu-is-open {
        border-color: var(--input-border-color);
        outline: none;
        box-shadow: none;

        &:hover {
          border-color: var(--input-border-color);
        }
      }
    }

    &__single-value,
    &__multi-value {
      color: var(--black);
      text-transform: capitalize;
    }

    &__dropdown-indicator {
      color: var(--black);
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      margin-top: 0;
      background: transparent;
      box-shadow: none;

      &-list {
        background-color: var(--white);
        box-shadow: 0 size(8) size(16) rgba($black, 0.15);
        border-radius: size(4);
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &__option {
      background-color: var(--white);
      padding: size(12) size(12);
      font-size: size(14);
      // font-weight: $fw-semibold;
      text-transform: capitalize;
      cursor: pointer;

      &:first-child {
        border-top-left-radius: size(4);
        border-top-right-radius: size(4);
      }
      &:last-child {
        border-bottom-left-radius: size(4);
        border-bottom-right-radius: size(4);
      }

      &--is-focused {
        background-color: transparent;
        color: $primary;
      }

      &--is-selected {
        background-color: $primary;
        color: $white;
      }
    }
  }

  &.size-sm {
    .form-select {
      &__control {
        min-height: unset;
      }

      &__single-value {
        font-size: size(14);
      }

      &__input-container {
        padding: size(2) 0;
        margin: 0;
        font-size: size(14);
      }

      &__indicator {
        padding-top: size(4);
        padding-bottom: size(5);
      }
    }
  }
}



// for Input Group
// Inout Group with React Select
.input-group {
  & > .rjs-select {
    &:nth-child(1) {
      .form-select__control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:nth-child(2) {
      .form-select__control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  // for Sizing
  // sm
  &-sm {
    .rjs-select {
      .form-select {
        &__control {
          min-height: unset;
        }

        &__single-value {
          font-size: size(14);
        }

        &__input-container {
          padding: size(2) 0;
          margin: 0;
          font-size: size(14);
        }

        &__indicator {
          padding-top: size(4);
          padding-bottom: size(5);
        }
      }
    }
  }
}
