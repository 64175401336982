.client-slider {
    // background-color: rgba($color: $warning, $alpha: 0.1);
    padding: 25px 10px;
    border-radius: 10px;
    .client-box {
        padding: 0 4px;
    }
}

// Slick Dot Navigation
.slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 15px 0 0;
    padding-left: 0;
    list-style: none;
    li {
        &.slick-active {
            button {
                height: 14px;
                width: 14px;
            }
        }

        button {
            height: 10px;
            width: 10px;
            padding: 0;
            background-color: var(--warning);
            border-radius: 100%;
            font-size: 0;
            border: none;
            @include css3-prefix(transition, all .4s ease);
        }
    }
}

// Client Box
.client-boxes {
    .card {
        flex: 0 0 auto;
        min-width: calc(100%/4 - 12px);
        max-width: calc(100%/4 - 12px);
        background-color: rgba($color: $primary, $alpha: .1);
        border: none;
        cursor: pointer;
        @include css3-prefix(transition, all .3s ease);
        &:hover {
            background-color: rgba($color: $primary, $alpha: .2);
        }

        @include media-breakpoint-down(xxxl) {
            min-width: calc(100%/3 - 12px);
        }
        @include media-breakpoint-down(xxl) {
            min-width: calc(100%/2 - 12px);
        }
        @include media-breakpoint-down(sm) {
            min-width: 100%;
        }
    }
}