.collapse-card {
	margin-bottom: size(24);

	.collapse-header {
		.collapse-header-inner {
			display: flex;
			width: 100%;
			justify-content: space-between;

			.collapse-trigger {
				display: block;
				@include font(14, 22);
				font-weight: $fw-semibold;
				color: #061237;

				img {
					margin-inline-end: size(8);
				}
			}

			.collapse-left {
				width: 100%;

				.disabled-text {
					@include font(13, 21);
					font-weight: $fw-regular;
				}
			}

			.collapse-right {
				display: flex;
				position: absolute;
				right: size(1);
				pointer-events: none;

				// .button-switch {
				// 	margin-inline-end: size(13);
				// 	pointer-events: all;
				// }

				.ico {
					margin-inline-end: size(16);
					pointer-events: none;
				}
			}
		}

	}

	.collapse {
		margin-top: size(16);
	}

	// &.switchOff {
	// 	.disabled-text {
	// 		height: auto;
	// 		margin: 0;
	// 	}
	// }

	// &.switchOn {
	// 	.disabled-text {
	// 		height: 0;
	// 		margin: 0;
	// 		overflow: hidden;
	// 	}
	// }
}
