
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */


a[aria-expanded="true"] {
  background: var(--body-bg);
}

a[data-toggle="collapse"] {
  position: relative;
}

// search off canvas
.offcanvasSearch {
  &.offcanvas {
    &.offcanvas-top {
      height: auto;
      max-height: max-content;
    }
  }
}
