
.search-terms-filters {

  &:hover {
    .close-btn {
      visibility: visible;
      opacity: 1;
    }
  }

  .filter-tag {
    border-style: solid;
    border-color: var(--global-border-color);
    border-width: size(1);
    padding: size(10) size(10) size(10) size(20);
    display: inline;
    position: relative;
    border-radius: size(5);
    font-size: size(14);

    .filter-ball {
      width: size(7);
      height: size(7);
      position: absolute;
      border-radius: size(50);
      left: size(8);
      top: size(14);
    }
  }

  .close-btn {
    visibility: hidden;
    opacity: 0;
  }
}

.user-dp-img {
  --width: 145px;
  --height: 145px;
  position: relative;
  background: rgba($color: $primary, $alpha: 0.05);
  border: size(1) solid rgba($color: $primary, $alpha: 0.05);
  width: var(--width);
  height: var(--height);
  border-radius: size(12);

  img {
    object-fit: cover;
    object-position: center;
    border-radius: inherit;
  }

  .img-upload {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    width: size(35);
    height: size(35);
    border-radius: 100%;
    color: var(--white);
    bottom: 0;
    right: 12px;
    cursor: pointer;
  }

  [type="file"] {
    display: none;
  }
}


.uploaded-images-container{
  .uploaded-img{
    width: 145px;
    height: 145px;
  }
}