
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  @include media-breakpoint-up(md) {
    padding-inline: size(30);
  }
}
