
.cf-menu {
  .dropdown-menu {
    .form-check {
      align-items: center;
      min-height: unset;
    }
    .form-check-label {
      display: inline-block;
      max-width: size(90);
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
