$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../fonts" !default;

$inic-payment: "\e99b";
$inic-codepen: "\e900";
$inic-bookmark: "\e901";
$inic-calender-tick: "\e902";
$inic-drag-indicator: "\e903";
$inic-verified: "\e904";
$inic-verify: "\e905";
$inic-discount: "\e906";
$inic-settings: "\e907";
$inic-work: "\e908";
$inic-import: "\e909";
$inic-call: "\e90a";
$inic-account-balance: "\e90b";
$inic-share: "\e90c";
$inic-common: "\e90d";
$inic-star: "\e90e";
$inic-chat-bubble: "\e90f";
$inic-slack: "\e910";
$inic-group: "\e911";
$inic-calendar: "\e912";
$inic-shop: "\e913";
$inic-map-route: "\e914";
$inic-language: "\e915";
$inic-crown: "\e916";
$inic-bin: "\e917";
$inic-dashboard: "\e918";
$inic-gift: "\e919";
$inic-qr-code: "\e91a";
$inic-person-pin: "\e91b";
$inic-print: "\e91c";
$inic-visibility-off: "\e91d";
$inic-edit: "\e91e";
$inic-event: "\e91f";
$inic-dislike: "\e920";
$inic-message: "\e921";
$inic-sun: "\e922";
$inic-support: "\e923";
$inic-like: "\e924";
$inic-volume-off: "\e925";
$inic-notifications-off: "\e926";
$inic-account-balance-wallet: "\e927";
$inic-orders: "\e928";
$inic-happy-emoji: "\e929";
$inic-camera-alt: "\e92a";
$inic-refresh: "\e92b";
$inic-emoji-events: "\e92c";
$inic-all-inbox: "\e92d";
$inic-ticket: "\e92e";
$inic-degree: "\e92f";
$inic-trophy: "\e930";
$inic-map: "\e931";
$inic-photo: "\e932";
$inic-videocam-off: "\e933";
$inic-add-folder: "\e934";
$inic-add-person: "\e935";
$inic-code: "\e936";
$inic-account-circle: "\e937";
$inic-volume-up: "\e938";
$inic-videocam: "\e939";
$inic-notifications-active: "\e93a";
$inic-https: "\e93b";
$inic-trash: "\e93c";
$inic-decending: "\e93d";
$inic-assending: "\e93e";
$inic-pause: "\e93f";
$inic-power-off: "\e940";
$inic-bar-chart: "\e941";
$inic-chart: "\e942";
$inic-copy: "\e943";
$inic-shopping-cart: "\e944";
$inic-mic-off: "\e945";
$inic-zoom-in: "\e946";
$inic-github: "\e947";
$inic-minus-folder: "\e948";
$inic-file: "\e949";
$inic-dollar: "\e94a";
$inic-timer-1: "\e94b";
$inic-restore: "\e94c";
$inic-archive: "\e94d";
$inic-minus-person: "\e94e";
$inic-phone: "\e94f";
$inic-person: "\e950";
$inic-mic: "\e951";
$inic-logout: "\e952";
$inic-login: "\e953";
$inic-inbox: "\e954";
$inic-moon: "\e955";
$inic-download: "\e956";
$inic-folder: "\e957";
$inic-desktop-mac: "\e958";
$inic-credit-card: "\e959";
$inic-zoom-out: "\e95a";
$inic-pie-graph: "\e95b";
$inic-send-1: "\e95c";
$inic-tag: "\e95d";
$inic-danger: "\e95e";
$inic-filter-1: "\e95f";
$inic-autorenew: "\e960";
$inic-visibility: "\e961";
$inic-email: "\e962";
$inic-export: "\e963";
$inic-gps-fixed: "\e964";
$inic-more-1: "\e965";
$inic-home: "\e966";
$inic-fast-rewind: "\e967";
$inic-search: "\e968";
$inic-sort: "\e969";
$inic-heart: "\e96a";
$inic-fast-forward: "\e96b";
$inic-flag: "\e96c";
$inic-eye: "\e96d";
$inic-upload: "\e96e";
$inic-rotate-left: "\e96f";
$inic-link: "\e970";
$inic-location-on: "\e971";
$inic-crop: "\e972";
$inic-analysis: "\e973";
$inic-cancel: "\e974";
$inic-format-align-right: "\e975";
$inic-timer: "\e976";
$inic-format-align-center: "\e977";
$inic-info: "\e978";
$inic-schedule: "\e979";
$inic-format-align-left: "\e97a";
$inic-error: "\e97b";
$inic-format-bold: "\e97c";
$inic-circle-tick: "\e97d";
$inic-format-align-justify: "\e97e";
$inic-done-all: "\e97f";
$inic-filter: "\e980";
$inic-star-filled: "\e981";
$inic-navigation: "\e982";
$inic-format-italic: "\e983";
$inic-menu: "\e984";
$inic-received: "\e985";
$inic-send: "\e986";
$inic-close: "\e987";
$inic-arrow-back: "\e988";
$inic-arrow-downward: "\e989";
$inic-arrow-upward: "\e98a";
$inic-arrow-forward: "\e98b";
$inic-slash: "\e98c";
$inic-add: "\e98d";
$inic-bottom-arrow: "\e98e";
$inic-up-arrow: "\e98f";
$inic-left-arrow-back: "\e990";
$inic-right-arrow: "\e991";
$inic-drag-handle: "\e992";
$inic-done: "\e993";
$inic-circle: "\e994";
$inic-arrow-left: "\e995";
$inic-arrow-up: "\e996";
$inic-arrow-drop-down: "\e997";
$inic-arrow-right: "\e998";
$inic-heart-filled: "\e999";
$inic-subtract-1: "\e99a";


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.inic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.inic-payment {
  &:before {
    content: $inic-payment; 
  }
}
.inic-codepen {
  &:before {
    content: $inic-codepen; 
  }
}
.inic-bookmark {
  &:before {
    content: $inic-bookmark; 
  }
}
.inic-calender-tick {
  &:before {
    content: $inic-calender-tick; 
  }
}
.inic-drag-indicator {
  &:before {
    content: $inic-drag-indicator; 
  }
}
.inic-verified {
  &:before {
    content: $inic-verified; 
  }
}
.inic-verify {
  &:before {
    content: $inic-verify; 
  }
}
.inic-discount {
  &:before {
    content: $inic-discount; 
  }
}
.inic-settings {
  &:before {
    content: $inic-settings; 
  }
}
.inic-work {
  &:before {
    content: $inic-work; 
  }
}
.inic-import {
  &:before {
    content: $inic-import; 
  }
}
.inic-call {
  &:before {
    content: $inic-call; 
  }
}
.inic-account-balance {
  &:before {
    content: $inic-account-balance; 
  }
}
.inic-share {
  &:before {
    content: $inic-share; 
  }
}
.inic-common {
  &:before {
    content: $inic-common; 
  }
}
.inic-star {
  &:before {
    content: $inic-star; 
  }
}
.inic-chat-bubble {
  &:before {
    content: $inic-chat-bubble; 
  }
}
.inic-slack {
  &:before {
    content: $inic-slack; 
  }
}
.inic-group {
  &:before {
    content: $inic-group; 
  }
}
.inic-calendar {
  &:before {
    content: $inic-calendar; 
  }
}
.inic-shop {
  &:before {
    content: $inic-shop; 
  }
}
.inic-map-route {
  &:before {
    content: $inic-map-route; 
  }
}
.inic-language {
  &:before {
    content: $inic-language; 
  }
}
.inic-crown {
  &:before {
    content: $inic-crown; 
  }
}
.inic-bin {
  &:before {
    content: $inic-bin; 
  }
}
.inic-dashboard {
  &:before {
    content: $inic-dashboard; 
  }
}
.inic-gift {
  &:before {
    content: $inic-gift; 
  }
}
.inic-qr-code {
  &:before {
    content: $inic-qr-code; 
  }
}
.inic-person-pin {
  &:before {
    content: $inic-person-pin; 
  }
}
.inic-print {
  &:before {
    content: $inic-print; 
  }
}
.inic-visibility-off {
  &:before {
    content: $inic-visibility-off; 
  }
}
.inic-edit {
  &:before {
    content: $inic-edit; 
  }
}
.inic-event {
  &:before {
    content: $inic-event; 
  }
}
.inic-dislike {
  &:before {
    content: $inic-dislike; 
  }
}
.inic-message {
  &:before {
    content: $inic-message; 
  }
}
.inic-sun {
  &:before {
    content: $inic-sun; 
  }
}
.inic-support {
  &:before {
    content: $inic-support; 
  }
}
.inic-like {
  &:before {
    content: $inic-like; 
  }
}
.inic-volume-off {
  &:before {
    content: $inic-volume-off; 
  }
}
.inic-notifications-off {
  &:before {
    content: $inic-notifications-off; 
  }
}
.inic-account-balance-wallet {
  &:before {
    content: $inic-account-balance-wallet; 
  }
}
.inic-orders {
  &:before {
    content: $inic-orders; 
  }
}
.inic-happy-emoji {
  &:before {
    content: $inic-happy-emoji; 
  }
}
.inic-camera-alt {
  &:before {
    content: $inic-camera-alt; 
  }
}
.inic-refresh {
  &:before {
    content: $inic-refresh; 
  }
}
.inic-emoji-events {
  &:before {
    content: $inic-emoji-events; 
  }
}
.inic-all-inbox {
  &:before {
    content: $inic-all-inbox; 
  }
}
.inic-ticket {
  &:before {
    content: $inic-ticket; 
  }
}
.inic-degree {
  &:before {
    content: $inic-degree; 
  }
}
.inic-trophy {
  &:before {
    content: $inic-trophy; 
  }
}
.inic-map {
  &:before {
    content: $inic-map; 
  }
}
.inic-photo {
  &:before {
    content: $inic-photo; 
  }
}
.inic-videocam-off {
  &:before {
    content: $inic-videocam-off; 
  }
}
.inic-add-folder {
  &:before {
    content: $inic-add-folder; 
  }
}
.inic-add-person {
  &:before {
    content: $inic-add-person; 
  }
}
.inic-code {
  &:before {
    content: $inic-code; 
  }
}
.inic-account-circle {
  &:before {
    content: $inic-account-circle; 
  }
}
.inic-volume-up {
  &:before {
    content: $inic-volume-up; 
  }
}
.inic-videocam {
  &:before {
    content: $inic-videocam; 
  }
}
.inic-notifications-active {
  &:before {
    content: $inic-notifications-active; 
  }
}
.inic-https {
  &:before {
    content: $inic-https; 
  }
}
.inic-trash {
  &:before {
    content: $inic-trash; 
  }
}
.inic-decending {
  &:before {
    content: $inic-decending; 
  }
}
.inic-assending {
  &:before {
    content: $inic-assending; 
  }
}
.inic-pause {
  &:before {
    content: $inic-pause; 
  }
}
.inic-power-off {
  &:before {
    content: $inic-power-off; 
  }
}
.inic-bar-chart {
  &:before {
    content: $inic-bar-chart; 
  }
}
.inic-chart {
  &:before {
    content: $inic-chart; 
  }
}
.inic-copy {
  &:before {
    content: $inic-copy; 
  }
}
.inic-shopping-cart {
  &:before {
    content: $inic-shopping-cart; 
  }
}
.inic-mic-off {
  &:before {
    content: $inic-mic-off; 
  }
}
.inic-zoom-in {
  &:before {
    content: $inic-zoom-in; 
  }
}
.inic-github {
  &:before {
    content: $inic-github; 
  }
}
.inic-minus-folder {
  &:before {
    content: $inic-minus-folder; 
  }
}
.inic-file {
  &:before {
    content: $inic-file; 
  }
}
.inic-dollar {
  &:before {
    content: $inic-dollar; 
  }
}
.inic-timer-1 {
  &:before {
    content: $inic-timer-1; 
  }
}
.inic-restore {
  &:before {
    content: $inic-restore; 
  }
}
.inic-archive {
  &:before {
    content: $inic-archive; 
  }
}
.inic-minus-person {
  &:before {
    content: $inic-minus-person; 
  }
}
.inic-phone {
  &:before {
    content: $inic-phone; 
  }
}
.inic-person {
  &:before {
    content: $inic-person; 
  }
}
.inic-mic {
  &:before {
    content: $inic-mic; 
  }
}
.inic-logout {
  &:before {
    content: $inic-logout; 
  }
}
.inic-login {
  &:before {
    content: $inic-login; 
  }
}
.inic-inbox {
  &:before {
    content: $inic-inbox; 
  }
}
.inic-moon {
  &:before {
    content: $inic-moon; 
  }
}
.inic-download {
  &:before {
    content: $inic-download; 
  }
}
.inic-folder {
  &:before {
    content: $inic-folder; 
  }
}
.inic-desktop-mac {
  &:before {
    content: $inic-desktop-mac; 
  }
}
.inic-credit-card {
  &:before {
    content: $inic-credit-card; 
  }
}
.inic-zoom-out {
  &:before {
    content: $inic-zoom-out; 
  }
}
.inic-pie-graph {
  &:before {
    content: $inic-pie-graph; 
  }
}
.inic-send-1 {
  &:before {
    content: $inic-send-1; 
  }
}
.inic-tag {
  &:before {
    content: $inic-tag; 
  }
}
.inic-danger {
  &:before {
    content: $inic-danger; 
  }
}
.inic-filter-1 {
  &:before {
    content: $inic-filter-1; 
  }
}
.inic-autorenew {
  &:before {
    content: $inic-autorenew; 
  }
}
.inic-visibility {
  &:before {
    content: $inic-visibility; 
  }
}
.inic-email {
  &:before {
    content: $inic-email; 
  }
}
.inic-export {
  &:before {
    content: $inic-export; 
  }
}
.inic-gps-fixed {
  &:before {
    content: $inic-gps-fixed; 
  }
}
.inic-more-1 {
  &:before {
    content: $inic-more-1; 
  }
}
.inic-home {
  &:before {
    content: $inic-home; 
  }
}
.inic-fast-rewind {
  &:before {
    content: $inic-fast-rewind; 
  }
}
.inic-search {
  &:before {
    content: $inic-search; 
  }
}
.inic-sort {
  &:before {
    content: $inic-sort; 
  }
}
.inic-heart {
  &:before {
    content: $inic-heart; 
  }
}
.inic-fast-forward {
  &:before {
    content: $inic-fast-forward; 
  }
}
.inic-flag {
  &:before {
    content: $inic-flag; 
  }
}
.inic-eye {
  &:before {
    content: $inic-eye; 
  }
}
.inic-upload {
  &:before {
    content: $inic-upload; 
  }
}
.inic-rotate-left {
  &:before {
    content: $inic-rotate-left; 
  }
}
.inic-link {
  &:before {
    content: $inic-link; 
  }
}
.inic-location-on {
  &:before {
    content: $inic-location-on; 
  }
}
.inic-crop {
  &:before {
    content: $inic-crop; 
  }
}
.inic-analysis {
  &:before {
    content: $inic-analysis; 
  }
}
.inic-cancel {
  &:before {
    content: $inic-cancel; 
  }
}
.inic-format-align-right {
  &:before {
    content: $inic-format-align-right; 
  }
}
.inic-timer {
  &:before {
    content: $inic-timer; 
  }
}
.inic-format-align-center {
  &:before {
    content: $inic-format-align-center; 
  }
}
.inic-info {
  &:before {
    content: $inic-info; 
  }
}
.inic-schedule {
  &:before {
    content: $inic-schedule; 
  }
}
.inic-format-align-left {
  &:before {
    content: $inic-format-align-left; 
  }
}
.inic-error {
  &:before {
    content: $inic-error; 
  }
}
.inic-format-bold {
  &:before {
    content: $inic-format-bold; 
  }
}
.inic-circle-tick {
  &:before {
    content: $inic-circle-tick; 
  }
}
.inic-format-align-justify {
  &:before {
    content: $inic-format-align-justify; 
  }
}
.inic-done-all {
  &:before {
    content: $inic-done-all; 
  }
}
.inic-filter {
  &:before {
    content: $inic-filter; 
  }
}
.inic-star-filled {
  &:before {
    content: $inic-star-filled; 
  }
}
.inic-navigation {
  &:before {
    content: $inic-navigation; 
  }
}
.inic-format-italic {
  &:before {
    content: $inic-format-italic; 
  }
}
.inic-menu {
  &:before {
    content: $inic-menu; 
  }
}
.inic-received {
  &:before {
    content: $inic-received; 
  }
}
.inic-send {
  &:before {
    content: $inic-send; 
  }
}
.inic-close {
  &:before {
    content: $inic-close; 
  }
}
.inic-arrow-back {
  &:before {
    content: $inic-arrow-back; 
  }
}
.inic-arrow-downward {
  &:before {
    content: $inic-arrow-downward; 
  }
}
.inic-arrow-upward {
  &:before {
    content: $inic-arrow-upward; 
  }
}
.inic-arrow-forward {
  &:before {
    content: $inic-arrow-forward; 
  }
}
.inic-slash {
  &:before {
    content: $inic-slash; 
  }
}
.inic-add {
  &:before {
    content: $inic-add; 
  }
}
.inic-bottom-arrow {
  &:before {
    content: $inic-bottom-arrow; 
  }
}
.inic-up-arrow {
  &:before {
    content: $inic-up-arrow; 
  }
}
.inic-left-arrow-back {
  &:before {
    content: $inic-left-arrow-back; 
  }
}
.inic-right-arrow {
  &:before {
    content: $inic-right-arrow; 
  }
}
.inic-drag-handle {
  &:before {
    content: $inic-drag-handle; 
  }
}
.inic-done {
  &:before {
    content: $inic-done; 
  }
}
.inic-circle {
  &:before {
    content: $inic-circle; 
  }
}
.inic-arrow-left {
  &:before {
    content: $inic-arrow-left; 
  }
}
.inic-arrow-up {
  &:before {
    content: $inic-arrow-up; 
  }
}
.inic-arrow-drop-down {
  &:before {
    content: $inic-arrow-drop-down; 
  }
}
.inic-arrow-right {
  &:before {
    content: $inic-arrow-right; 
  }
}
.inic-heart-filled {
  &:before {
    content: $inic-heart-filled; 
  }
}
.inic-subtract-1 {
  &:before {
    content: $inic-subtract-1; 
  }
}

