.list-group-item {
  &.active {
    z-index: unset;
  }
}

// Pill List
.pill-list {
  max-height: 228px;
  overflow-y: auto;
  .list-group-item {
    position: relative;
    padding: 8px 0 8px 20px;
    background: transparent;
    color: var(--dark);
    font-weight: $fw-medium;
    font-size: size(14);
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 6px;
      height: 6px;
      background-color: var(--gray);
      border-radius: 100%;
      @include css3-prefix(transform, translateY(-50%));
    }
  }
}
.aside-card-list {
  max-height: 400px;
  overflow-y: auto;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.simple-list {
  .list-group-item {
    border: none;
    padding-inline: 0;
    font-size: size(14);
    background-color: transparent;
  }
}
