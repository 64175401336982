
// CK Editor V5 (36.0.0 Classic Editor Build)
// https://ckeditor.com/ckeditor-5/download/

:root {
	//////////////////
	// for Light Theme



	/////////////////
	// for Dark Theme
	&[data-theme=dark] {
    .ck {
      &.ck-toolbar {
        .ck {
          &.ck-toolbar__separator {
            background: var(--dark) !important;
          }
        }
      }
    }
  }
}

.ck-reset_all {
  :not(.ck-reset_all-excluded *) {
    color: var(--dark) !important;
  }
}

.ck {
  &.ck-reset_all {
    color: var(--dark) !important;
  }

  &.ck-editor {
    &__main {
      & > .ck-editor {
        &__editable {
          background: var(--white) !important;

          &:not(.ck-focused) {
            border-color: var(--global-border-color) !important;
          }
        }
      }
    }
  }

  &.ck-editor__main {
    & > .ck-editor__editable {
      &:not(.ck-focused) {
        border-bottom-left-radius: size(6) !important;
        border-bottom-right-radius: size(6) !important;
      }
    }
  }

  &.ck-editor__top {
    .ck-sticky-panel {
      .ck-toolbar {
        &.ck-rounded-corners {
          border-top-left-radius: size(6) !important;
          border-top-right-radius: size(6) !important;
        }
      }
    }
  }

  &.ck-toolbar {
    background: transparent !important;
    border-color: var(--global-border-color) !important;
  }

  &.ck-list__item {
    .ck-button {
      &.ck-on {
        background: $primary !important;
        color: $white !important;
      }
    }
  }
}

.ck-rounded-corners {
  .ck {
    &.ck-editor__top {
      .ck-sticky-panel {
        .ck-toolbar {
          border-top-left-radius: size(6) !important;
          border-top-right-radius: size(6) !important;
        }
      }
    }
  }
}
