

:root {
  //////////////////
  // for Light Theme



  /////////////////
  // for Dark Theme
  &[data-theme="dark"] {
    img {
      &.sidebar-comapny-logo {
        filter: invert(97%) sepia(3%) saturate(0%) hue-rotate(333deg) brightness(104%) contrast(106%);
      }
    }

    // for Invert Image Colors in Dark Mode
    .invert-img {
      filter: invert(97%) saturate(50%) brightness(100%) contrast(100%);
    }
  }
}



// Layout
body,
html {
  height: 100%;
}

body {
  background-color: var(--white);
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: 1.5;
  font-weight: $fw-regular;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // Transition for colors ~
  // transition-property: color, background-color, border-color;
  // transition-duration: 0.15s;
  // transition-timing-function: ease-in-out;
}


// Begin: Links
a {
  color: $primary;
  cursor: pointer;
  outline: 0;
  text-decoration: $link-decoration;

  &:hover,
  &:focus {
    text-decoration: $link-decoration;
    box-shadow: none;
    outline: 0;
  }
}

// for Override Font Family
pre {
  font-family: $font-family-base;
  margin: 0;
}

// for Focus
:focus-visible {
  outline: none;
}

// for Cursor
.cursor-pointer {
  cursor: pointer;
}

// for Pointer Events
.pointer-events-none {
  pointer-events: none !important;
}

// Global Body ~~~~~
.global-body {
  @include css3-prefix(transition, width 0.35s ease-in-out);

  @media screen and (min-width: 1367px) {
    width: calc(100% - size(224));
    margin-inline-start: auto;
  }

  @media screen and (max-width: 1366px) {
    padding-top: size(60)
  }
}

// Sidebar Collapsed ~~~~~
.sidebar-collapsed {
  overflow: hidden;
  .global-body {
    @include css3-prefix(transition, width 0.35s ease-in-out);

    @media screen and (min-width: 1367px) {
      width: calc(100% - size(60));
    }
  }
}


// offcanvas blur
// .show-blur {
//   filter: blur(0);
// }


// language dropdown
// .language-selection-dropdown{
//   max-width: size(150);
// }

// Object Fit
.object-cover {
  object-fit: cover;
}
.object-contain {
  object-fit: contain;
}
.object-center {
  object-position: center;
}
.img-full{
  width: 100%;
  height: 100%;
}


// User Details View/Add/Edit Card
.user-card-block {
  --adjustable-width: 145px;
  --adjustable-height: 145px;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  .user-dp-img {
    position: relative;
    background: rgba($color: $primary, $alpha: 0.05);
    border: size(1) solid rgba($color: $primary, $alpha: 0.05);
    width: var(--adjustable-width);
    height: var(--adjustable-height);
    border-radius: size(12);
  
    img {
      object-fit: cover;
      object-position: center;
      border-radius: inherit;
    }
  
    .img-upload {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      font-size: size(18);
      color: var(--white);
      bottom: 0;
      right: 12px;
      cursor: pointer;
    }
  
    [type="file"] {
      display: none;
    }
  }

  .user-card-block-inner {
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: calc(100% - var(--adjustable-width));
      padding-inline-start: size(20);
    }
  }
}

// React Tooltip 
.react-tooltip {
  &.react-custom-tooltip{
    padding: 6px 10px;
    line-height: normal;
  }
} 

.sub-list {
  margin-top: 5px;
  padding: 0;
  list-style: none;
  li {
    position: relative;
    padding-inline-start: 40px;
    &::after {
      content: '';
      position: absolute;
      top: 7px;
      left: 25px;
      width: 5px;
      height: 5px;
      background-color: $primary;
      border-radius: 100%;
    }
  }
}