.offcanvas-50{
  &.offcanvas{
    &.offcanvas-end{
      $offcanvas-horizontal-width: calc(50vw - size(16));
      width: $offcanvas-horizontal-width;
    }
    .dropzone{
        min-height: auto;
    }
  }
  
}
.table-ellipsis{
  table-layout:fixed
}

.filter-list {
  @include media-breakpoint-down(md) {
    justify-content: flex-start!important;
  }
}

.blogview{
  display: none;
  &.gridView-visible,
  &.listView-visible{
    display: block;
  }
}

.blog-desc{
  width: 300px;
}

// Card for Blog
.card-blog {
	min-height: 100%;
	margin: 0;

	// My Card Image Box
	.card-img-box {
		flex-shrink: 0;
		border-top-left-radius: size(15);
		border-top-right-radius: size(15);
		overflow: hidden;

		@include media-breakpoint-down(md) {
			border-top-left-radius: size(8);
			border-top-right-radius: size(8);
		}

		img {
			width: 100%;
		}
	}
	// My Card Body
	.card-body {
    flex-shrink: 0;
    flex-grow: 1;
		display: flex;
		flex-direction: column;
		max-height: 100%;
		// padding-bottom: 0;
	}
	// My Card Title
	.card-title {
		padding-top: size(16);
		& > * {
			&:only-child {
				margin-bottom: 0;
			}
			&:not(:only-child) {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	// My Card Description
	.card-desc {
		padding-top: size(16);
		* {
			margin-bottom: 0;
		}
	}
	// My Card Footer
	.card-footer {
		margin-top: auto;
	}
}

// My Card Badge Box
.card-badge-box {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	.badge {
		margin-top: size(4);
		margin-inline-end: size(4);
	}
}
