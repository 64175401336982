

:root {
  // for LIGHT Theme
  // CSS Variables Generator
  @each $color-name, $color-value in $theme-colors {
    --#{$color-name}: #{$color-value};

    // Text Color Classes Generator
    .text-#{$color-name} {
      color: $color-value !important;
    }
    // Background Color Classes Generator
    .bg-#{$color-name} {
      background-color: $color-value !important;
    }
    // Border Color Classes Generator
    .border-#{$color-name} {
      border-color: $color-value !important;
    }

    .link-#{$color-name} {
      color: $color-value !important; // stylelint-disable-line declaration-no-important

      @if $link-shade-percentage !=0 {
        &:hover,
        &:focus {
          color: if(color-contrast($color-value)==$color-contrast-light, shade-color($color-value, $link-shade-percentage), tint-color($color-value, $link-shade-percentage)) !important; // stylelint-disable-line declaration-no-important
        }
      }
    }
  }

  // for DARK Theme
  &[data-theme="dark"] {
    // CSS Variables Generator
    @each $color-name, $color-value in $theme-colors-dark {
      --#{$color-name}: #{$color-value};

      // Text Color Classes Generator
      .text-#{$color-name} {
        color: $color-value !important;
      }
      // Background Color Classes Generator
      .bg-#{$color-name} {
        background-color: $color-value !important;
      }
      // Border Color Classes Generator
      .border-#{$color-name} {
        border-color: $color-value !important;
      }

      .link-#{$color-name} {
        color: $color-value !important; // stylelint-disable-line declaration-no-important

        @if $link-shade-percentage !=0 {
          &:hover,
          &:focus {
            color: if(color-contrast($color-value)==$color-contrast-light, shade-color($color-value, $link-shade-percentage), tint-color($color-value, $link-shade-percentage)) !important; // stylelint-disable-line declaration-no-important
          }
        }
      }
    }
  };
};

// for Fill Buttons - Lighter Versions
@each $color, $value in $theme-colors {
  .bg-#{$color}-light {
		background-color: rgba($value, 10%) !important;
	}
}
