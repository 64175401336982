
:root {
  //////////////////
  // for Light Theme



  /////////////////
  // for Dark Theme
  &[data-theme=dark] {
    .table {
      &.table-hover,
      &.common-table {
        & > tbody {
          & > tr {
            &:hover > * {
              background: rgba($primary, 0.15);
            }
          }
        }
      }
    }

  }
}


.table {

  &:not(caption) {
    padding: 0.6rem;
  }

  thead {
    border-top: size(1) solid var(--global-border-color);
    border-bottom: size(1) solid var(--global-border-color);
  
    tr {
      border-left: size(4) solid transparent;
    }
  }

  th {
    padding: size(10) size(8);
    @include font(14, 22);
    font-weight: $fw-bold;
    color: var(--dark);

    &:first-child {
      border-radius: 0;
    }
    &:last-child {
      border-radius: 0;
    }    
  }

  td {
    @include font(14, 22);
    font-weight: $fw-regular;
    color: var(--dark);

    .profile-pic-user {
      display: flex;
      align-items: center;
      color: $dark;
    }
  }
  
  tbody {
    tr {
      border-left: size(4) solid transparent;
      vertical-align: middle;
  
      &:hover {
        border-left-color: var(--primary);
  
        & > * {
          color: var(--primary);
        }
  
        th {
          border-radius: 0;
  
          .my-checkbox {
            vertical-align: middle;
          }
        }
      }
    }
  }

  tr {
    &:hover {
      // for language group table
      .language-group {
        .bx,
        .inic {
          color: transparent !important;
        }
      }
    }
  }

  &.table-hover,
  &.common-table {
    & > tbody {
      & > tr {
        &:hover > * {
          background: rgba($primary, 0.05);
        }
      }
    }
  }

  .dropdown-menu {
    padding: size(0);
    min-width: size(25);

    .dropdown-item {
      padding: size(7);
      font-size: size(14);
      text-align: center;
    }
  }
}

// for Email Status
.email-status {
  .email-badge {
    width: size(12);
    height: size(12);
    border: size(3) solid var(--global-border-color) !important;
  }
}

.email-status-badge {
  display: inline-block;
  width: size(12);
  height: size(12);
  margin-inline-end: size(6);
  border: size(3) solid var(--global-border-color);
  border-radius: 50%;
  overflow: hidden;

  &.status-success {
    background: $success;
  }

  &.status-failed {
    background: $danger;
  }
}

// themed table
.user-theme-card {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
      display: block !important;
    }
  }

  .table-card-header {
    display: flex;

    @include media-breakpoint-down(xxl) {
      justify-content: space-between;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .action-btns {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;

      @include media-breakpoint-down(sm) {
        margin-top: size(20);
      }
    }
  }

  .table-footer {
    display: flex;
    justify-content: space-between;
    align-content: center;

    @include media-breakpoint-down(md) {
      display: block;

      .show-pagination-results,
      .showing-results {
        margin-bottom: size(15);
        display: block;
        text-align: center;

        .form-select {
          width: size(80);
        }
      }

      .pagination {
        justify-content: center;
      }
    }
  }
}

.sorting,
.sorting-ascending,
.sorting-descending {
  padding-inline-end: size(20);
  white-space: nowrap;
  position: relative;

  &::after {
    display: inline-block;
    font-family: $ff-box-icons;
    font-size: size(12);
    margin-inline-start: size(10);
  }
}

.sorting {
  &::after {
    content: "\ec1a";
  }
}
.sorting-ascending {
  &::after {
    content: "\ec1d";
  }
}
.sorting-descending {
  &::after {
    content: "\ec1e";
  }
}

.show-pagination-results {
  &.form-select {
    font-size: size(12);
    background-size: size(8);
    margin-left: size(10);
    background-position: right 0.3rem center;
  }

  span {
    font-size: size(12);
  }

  @include media-breakpoint-down(md) {
    .flex-middle {
      justify-content: center;
    }
  }
}

.pagination {
  .page-item {
    --bs-pagination-border-width: size(0);
    margin-right: size(10);
    background-color: transparent;

    .page-link {
      display: grid;
      place-items: center;
      width: size(34);
      height: size(34);
      flex-shrink: 0;
      border: none;
      padding: size(4);
      border-radius: 100%;
      background-color: inherit;
      color: var(--dark);

      &:hover {
        background-color: rgba($primary, 0.15);
      }

      &.active {
        color: $white;
        background-color: $primary;

        &:hover {
          color: $white;
          background-color: $primary;
        }
      }

      &:focus {
        background-color: transparent;
        outline: none;
        box-shadow: none;
      }
    }

    &:hover {
      .page-link {
        background-color: rgba($primary, 0.15);
      }
    }
    &.active {
      .page-link {
        color: $white;
        background-color: $primary;
      }
    }

  }
}

// table flag
.table-flag-24 {
  width: size(24);
  height: size(24);
}
.packages-table {
  th,td {
    padding: 5px 10px;
  }
}