

// Global Style
:root {
  //////////////////
  // for Light Theme



  /////////////////
  // for Dark Theme
  &[data-theme="dark"] {
    .download-box {

      .dlBox {
        background-color: rgba($black, 0.25);

        .dlBox-left {
          border-inline-end-color: rgba($white, 0.1);
        }
      }

      // Tags
      .tags {
        border-color: rgba($white, 0.5);
        color: rgba($white, 0.5);
      }
    }

    .column-selection-listing {
      & > li {
        &:hover {
          background-color: rgba($white, 0.10);
        }
      }
    }
  }
}

.download-box {
  display: none;
  overflow: hidden;
  @include css3-prefix(transition, display 0.3s ease);

  & > * {
    height: 0;
    @include css3-prefix(transition, height 1s ease);
  }

  &.show {
    display: block;

    & > * {
      height: auto;
    }
  }

  .dlBox {
    --dlBox-left-width: 33.33%;

    background-color: rgba($black, 0.02);
    border-radius: size(15);
    overflow: hidden;
    margin-top: size(16);

    &-body {
      display: flex;
      background-color: inherit;
      padding: size(20);

      @include media-breakpoint-down(md) {
        flex-direction: column;
        gap: size(20);
      }
    }

    .dlBox-left {
      @include media-breakpoint-up(md) {
        width: var(--dlBox-left-width);
        border-inline-end: size(1) solid rgba($black, 0.05);
        padding-inline-end: 12px;
      }
      // @include media-breakpoint-up(lg) {
      //   width: var(--dlBox-left-width);
      // }
    }
    .dlBox-right {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      @include media-breakpoint-up(md) {
        width: calc(100% - var(--dlBox-left-width));
        padding-inline-start: 12px;
      }
      // @include media-breakpoint-up(lg) {
      //   width: calc(100% - var(--dlBox-left-width));
      // }
      @include media-breakpoint-down(md) {
        padding-top: size(24);
        border-top: size(1) solid rgba($black, 0.05);
      }

      .saved-ft {
        @include media-breakpoint-down(md) {
          padding-bottom: size(12);
          margin-bottom: size(24);
          border-bottom: size(1) solid rgba($black, 0.05);
        }
      }
    }
  }

  // Tags
  .tags {
    border-color: rgba($black, 0.5);
    color: rgba($black, 0.5);

    &:hover {
      .inic {
        display: inline-block;
      }
    }

    .inic {
      display: none;
      margin-inline-start: size(6);
      cursor: pointer;
      @include css3-prefix(transition, display 0.35s ease);
    }
  }
}

// Saved Filters
.saved-ft {
  @include list-unstyled;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > li {
    margin-inline-end: size(12);
    margin-bottom: size(6);
  }
}

// Column Selection Listing
.column-selection-listing {
  padding-inline-start: 0;
  margin: 0;
  // @include media-breakpoint-up(xl) {
  //   height: 100%;
  //   overflow-y: auto;
  // }
  // @include media-breakpoint-down(xl) {
  //   display: flex;
  //   white-space: nowrap;
  //   padding: size(6) size(4);
  //   overflow-x: auto;
  // }

  & > li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-block: size(12);
    padding-inline-end: size(8);
    // padding: size(12) size(8) size(12);
    @include css3-prefix(transition, padding-inline-start 0.15s linear);
    // @include media-breakpoint-up(xl) {
    &:hover {
      background-color: rgba($black, 0.05);
      padding-inline-start: size(8);

      .drg-handle {
        color: $primary
      }
    }
    // }
    // @include media-breakpoint-down(xl) {
    //   background-color: rgba($black, 0.05);
    //   &:not(:last-child) {
    //     margin-inline-end: size(4);
    //   }
    // }
  }

  .drg-handle {
    display: inline-block;
    margin-inline-end: size(8);
    font-size: size(14);
    color: var(--dark);
    cursor: pointer;
  }

  .form-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;

    label {
      padding-inline-start: size(8);
      font-weight: $fw-regular;
      font-size: size(13);
      line-height: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.show { 
  > .btn-primary {
    &:focus:not(:focus-visible),
    &.dropdown-toggle {
      color: var(--white);
    }
  }
}
