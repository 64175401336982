
@each $color, $value in $theme-colors {
  .badge-#{$color}-light {
		background-color: rgba($value, 0.2);
		color: mix($value, black, 75%);
  }
}


.badge {
  text-align: start;
  white-space: normal;
  line-height: 1.25;

  &:not([class*="badge-"]) {
    background-color: rgba($gray, 0.2);
		color: mix($gray, black, 75%);
  }
}
