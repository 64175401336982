.file-upload-block {
	display: flex;
	flex-direction: column;

	.fileupload-lable {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 9;
	}

	.img-preview {
		padding: size(10);
		display: flex;
		align-items: center;
		justify-content: center;

		.bx {
			position: absolute;
		}
		img{
			width: 100%;
			display: block;
			margin: 0 auto;
			height: size(50);
			object-fit: cover;
		}	
	}

	.img-preview-sm {
		min-width: size(100);
		width: size(100);
		width: fit-content;
	}
}