// inbox scrip
.card-inbox {
	padding: size(16);
	cursor: pointer;
	border-color: #E7ECF2;
	border-radius: 0;

	.card-inbox-header {
		display: flex;

		.card-inbox-sender-image {
			.profile-img {
				margin-inline-end: size(12);
			}
		}

		.card-inbox-dec {
			display: flex;
			flex-direction: column;
			width: 100%;

			.card-inbox-top {
				display: flex;
				justify-content: space-between;

				.card-inbox-left {
					margin-inline-end: size(12);

					.sender-name {
						font-weight: $fw-regular;
						@include font(13, 21);
						color: #9CA8BA;
					}

					.email-title {
						@include font(14, 22);
						color: #2E4168;
					}
				}

				.card-inbox-right {
					display: flex;
					flex-direction: column;
					align-items: end;

					.email-time {
						font-weight: $fw-regular;
						@include font(13, 21);
						color: #9CA8BA;
					}

					.email-action {
						display: flex;
						margin-top: size(4);

						a {
							margin-inline-end: size(8);
							display: flex;
						}
					}
				}
			}

			.msg-summary {
				margin-top: size(13);

				p {
					font-weight: $fw-regular;
					@include font(13, 21);
					color: #576073;
					margin: 0;
				}
			}
		}
	}
}

// inbox open mail
.card-mailbox {
	.card-mailbox-header {
		display: flex;
		padding: size(16);

		.card-mailbox-dec {
			display: flex;
			width: 100%;

			.to-from {
				display: flex;
				justify-content: space-between;
				width: 100%;

				.card-mailbox-left {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					margin: size(4) 0;

					.from-mail {
						@include font(14, 17);
						letter-spacing: -0.02em;
						color: #576073;
						font-weight: $fw-regular;
					}

					.to-mail {
						@include font(14, 17);
						letter-spacing: -0.02em;
						color: #576073;
						font-weight: $fw-regular;
					}
				}

				.card-mailbox-right {
					.email-action {
						display: flex;

						a {
							padding-inline-end: size(12);
						}

						.email-time {
							@include font(12, 20);
							color: #9CA8BA;
							font-weight: $fw-regular;
							padding-inline-end: size(12);
						}
					}
				}
			}
		}
	}

	.card-mailbox-body {
		padding: size(8) size(16) size(16);

		.mail-title {
			font-weight: $fw-semibold;
			margin-bottom: size(20);
		}

		.hello-recipient {
			@include font(14, 17);
			letter-spacing: -0.02em;
			font-weight: $fw-regular;
			color: #576073;
			margin-bottom: size(11);
		}

		.mail-content {
			@include font(13, 21);
			letter-spacing: -0.25px;
			font-weight: $fw-regular;
			color: #576073;
		}
	}
}

// mail attachments
.attached-wrapper {

	.attached-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: size(15);

		h5 {
			@include font(16, 25);
			letter-spacing: -0.02em;
			color: #2E4168;
			margin: 0;
		}

		a {
			@include font(13, 21);
			letter-spacing: -0.02em;
			color: #1B5BF5;
		}
	}

	.attached-body {
		display: flex;

		.attached-box {
			padding: size(16);
			width: size(185);
			background: #F8F9FB;
			border: size(1) solid #E7ECF2;
			border-radius: size(12);
			display: flex;
			align-items: center;
			margin: 0 size(8) size(16);

			.attached-icon {
				.ico {
					font-size: size(25);
					margin-inline-end: size(10);
				}
			}

			.file-name-size {
				.file-name {
					font-weight: $fw-regular;
					@include font(14, 17);
					letter-spacing: -0.02em;
					margin: 0;
					color: #2E4168;
				}

				.file-size {
					font-weight: $fw-regular;
					@include font(10, 16);
					letter-spacing: -0.02em;
					margin: 0;
					color: #9CA8BA;
				}
			}
		}
	}
}

// mail footer action buttons
.ftr-btn-wrapper {
	display: flex;
	justify-content: space-between;

	.ftr-btn-common {
		display: flex;

		.ftr-btn {
			font-weight: $fw-regular;
			@include font(13, 21);
			letter-spacing: -0.02em;
			color: #576073;
			display: inline-flex;
			align-items: center;
			margin-inline-end: size(25);

			.ico {
				font-size: size(17);
				margin-inline-end: size(12);
			}

			&:last-child {
				margin-inline-end: 0;
			}
		}
	}
}


// .email-notification-card {
// 	margin-bottom: size(24);

// 	.card-header {
// 		border: 0;
// 		background: transparent;
// 		// padding: 0;
// 		padding-bottom: size(16);

// 		.card-main-titel {
// 			margin: 0;
// 		}

// 		.card-subtitel {
// 			margin: 0;
// 			@include font(10, 16);
// 			font-weight: $fw-regular;
// 			letter-spacing: -0.02em;
// 			color: #576073;
// 		}
// 	}

// 	.card-body {
// 		// padding: 0;
// 	}
// }

// .notification-box {
// 	display: flex;
// 	align-items: center;

// 	.notification-content {
// 		width: 100%;

// 		.notification-label {
// 			@include font(13, 21);
// 			letter-spacing: -0.02em;
// 			color: #061237;
// 			margin: 0;
// 		}

// 		.notification-inst {
// 			font-weight: $fw-regular;
// 			@include font(10, 16);
// 			letter-spacing: -0.02em;
// 			color: #576073;
// 			margin: 0;
// 		}
// 	}
// }