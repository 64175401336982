
// Inline Start
@mixin flex-start {
  display: flex !important;
  justify-content: flex-start;
}
@mixin inline-flex-start {
  display: inline-flex !important;
  justify-content: flex-start;
}

// Inline Center
@mixin flex-center {
  display: flex !important;
  justify-content: center;
}
@mixin inline-flex-center {
  display: inline-flex !important;
  justify-content: center;
}

// Inline End
@mixin flex-end {
  display: flex !important;
  justify-content: flex-end;
}
@mixin inline-flex-end {
  display: inline-flex !important;
  justify-content: flex-end;
}

// Inline Around
@mixin flex-around {
  display: flex !important;
  justify-content: space-around;
}
@mixin inline-flex-around {
  display: inline-flex !important;
  justify-content: space-around;
}

// Inline Between
@mixin flex-between {
  display: flex !important;
  justify-content: space-between;
}
@mixin inline-flex-between {
  display: inline-flex !important;
  justify-content: space-between;
}

// Inline Evenly
@mixin flex-evenly {
  display: flex !important;
  justify-content: space-evenly;
}
@mixin inline-flex-evenly {
  display: inline-flex !important;
  justify-content: space-evenly;
}

// Verticle Start
@mixin flex-top {
  display: flex !important;
  align-items: flex-start;
}
@mixin inline-flex-top {
  display: inline-flex !important;
  align-items: flex-start
}

// Verticle Center
@mixin flex-middle {
  display: flex !important;
  align-items: center;
}
@mixin inline-flex-middle {
  display: inline-flex !important;
  align-items: center;
}

// Verticle End
@mixin flex-bottom {
  display: flex !important;
  align-items: flex-end;
}
@mixin inline-flex-bottom {
  display: inline-flex !important;
  align-items: flex-end;
}

// Verticle Baseline
@mixin flex-baseline {
  display: flex !important;
  align-items: baseline;
}
@mixin inline-flex-baseline {
  display: inline-flex !important;
  align-items: baseline;
}

// Verticle Stretch
@mixin flex-stretch {
  display: flex !important;
  align-items: stretch;
}
@mixin inline-flex-stretch {
  display: inline-flex !important;
  align-items: stretch;
}

// Inline Start - Verticle Start
@mixin flex-start-top {
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
}
@mixin inline-flex-start-top {
  display: inline-flex !important;
  justify-content: flex-start;
  align-items: flex-start;
}

// Inline Start - Verticle Center
@mixin flex-start-middle {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
}
@mixin inline-flex-start-middle {
  display: inline-flex !important;
  justify-content: flex-start;
  align-items: center;
}

// Inline Start - Verticle End
@mixin flex-start-bottom {
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-end;
}
@mixin inline-flex-start-bottom {
  display: inline-flex !important;
  justify-content: flex-start;
  align-items: flex-end;
}

// Inline Start - Verticle Baseline
@mixin flex-start-baseline {
  display: flex !important;
  justify-content: flex-start;
  align-items: baseline;
}
@mixin inline-flex-start-baseline {
  display: inline-flex !important;
  justify-content: flex-start;
  align-items: baseline;
}

// Inline Start - Verticle Stretch
@mixin flex-start-stretch {
  display: flex !important;
  justify-content: flex-start;
  align-items: stretch;
}
@mixin inline-flex-start-stretch {
  display: inline-flex !important;
  justify-content: flex-start;
  align-items: stretch;
}

// Inline Center - Verticle Start
@mixin flex-center-top {
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
}
@mixin inline-flex-center-top {
  display: inline-flex !important;
  justify-content: center;
  align-items: flex-start;
}

// Inline Center - Verticle Center
@mixin flex-center-middle {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
@mixin inline-flex-center-middle {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}

// Inline Center - Verticle End
@mixin flex-center-bottom {
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
}
@mixin inline-flex-center-bottom {
  display: inline-flex !important;
  justify-content: center;
  align-items: flex-end;
}

// Inline Center - Verticle Baseline
@mixin flex-center-baseline {
  display: flex !important;
  justify-content: center;
  align-items: baseline;
}
@mixin inline-flex-center-baseline {
  display: inline-flex !important;
  justify-content: center;
  align-items: baseline;
}


// Inline Center - Verticle Stretch
@mixin flex-center-stretch {
  display: flex !important;
  justify-content: center;
  align-items: stretch;
}
@mixin inline-flex-center-stretch {
  display: inline-flex !important;
  justify-content: center;
  align-items: stretch;
}

// Inline End - Verticle Start
@mixin flex-end-top {
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-start;
}
@mixin inline-flex-end-top {
  display: inline-flex !important;
  justify-content: flex-end;
  align-items: flex-start;
}

// Inline End - Verticle Center
@mixin flex-end-middle {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
}
@mixin inline-flex-end-middle {
  display: inline-flex !important;
  justify-content: flex-end;
  align-items: center;
}

// Inline End - Verticle End
@mixin flex-end-bottom {
  display: flex !important;
  justify-content: flex-end;
  align-items: flex-end;
}
@mixin inline-flex-end-bottom {
  display: inline-flex !important;
  justify-content: flex-end;
  align-items: flex-end;
}

// Inline End - Verticle Baseline
@mixin flex-end-baseline {
  display: flex !important;
  justify-content: flex-end;
  align-items: baseline;
}
@mixin inline-flex-end-baseline {
  display: inline-flex !important;
  justify-content: flex-end;
  align-items: baseline;
}

// Inline End - Verticle Stretch
@mixin flex-end-stretch {
  display: flex !important;
  justify-content: flex-end;
  align-items: stretch;
}
@mixin inline-flex-end-stretch {
  display: inline-flex !important;
  justify-content: flex-end;
  align-items: stretch;
}

// Inline End - Verticle Start
@mixin flex-around-top {
  display: flex !important;
  justify-content: space-around;
  align-items: flex-start;
}
@mixin inline-flex-around-top {
  display: inline-flex !important;
  justify-content: space-around;
  align-items: flex-start;
}

// Inline Around - Verticle Center
@mixin flex-around-middle {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
@mixin inline-flex-around-middle {
  display: inline-flex !important;
  justify-content: space-around;
  align-items: center;
}

// Inline Around - Verticle End
@mixin flex-around-bottom {
  display: flex !important;
  justify-content: space-around;
  align-items: flex-end;
}
@mixin inline-flex-around-bottom {
  display: inline-flex !important;
  justify-content: space-around;
  align-items: flex-end;
}

// Inline Around - Verticle Baseline
@mixin flex-around-baseline {
  display: flex !important;
  justify-content: space-around;
  align-items: baseline;
}
@mixin inline-flex-around-baseline {
  display: inline-flex !important;
  justify-content: space-around;
  align-items: baseline;
}

// Inline Around - Verticle Stretch
@mixin flex-around-stretch {
  display: flex !important;
  justify-content: flex-end;
  align-items: stretch;
}
@mixin inline-flex-around-stretch {
  display: inline-flex !important;
  justify-content: flex-end;
  align-items: stretch;
}

// Inline Between - Verticle Start
@mixin flex-between-top {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
}
@mixin inline-flex-between-top {
  display: inline-flex !important;
  justify-content: space-between;
  align-items: flex-start;
}

// Inline Between - Verticle Center
@mixin flex-between-middle {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
@mixin inline-flex-between-middle {
  display: inline-flex !important;
  justify-content: space-between;
  align-items: center;
}

// Inline Between - Verticle End
@mixin flex-between-bottom {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-end;
}
@mixin inline-flex-between-bottom {
  display: inline-flex !important;
  justify-content: space-between;
  align-items: flex-end;
}

// Inline Between - Verticle Baseline
@mixin flex-between-baseline {
  display: flex !important;
  justify-content: space-between;
  align-items: baseline;
}
@mixin inline-flex-between-baseline {
  display: inline-flex !important;
  justify-content: space-between;
  align-items: baseline;
}

// Inline Between - Verticle Stretch
@mixin flex-between-stretch {
  display: flex !important;
  justify-content: space-between;
  align-items: stretch;
}
@mixin inline-flex-between-stretch {
  display: inline-flex !important;
  justify-content: space-between;
  align-items: stretch;
}

// Inline Evenly - Verticle Start
@mixin flex-evenly-top {
  display: flex !important;
  justify-content: space-evenly;
  align-items: flex-start;
}
@mixin inline-flex-evenly-top {
  display: inline-flex !important;
  justify-content: space-evenly;
  align-items: flex-start;
}

// Inline Evenly - Verticle Center
@mixin flex-evenly-middle {
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
}
@mixin inline-flex-evenly-middle {
  display: inline-flex !important;
  justify-content: space-evenly;
  align-items: center;
}

// Inline Evenly - Verticle End
@mixin flex-evenly-bottom {
  display: flex !important;
  justify-content: space-evenly;
  align-items: flex-end;
}
@mixin inline-flex-evenly-bottom {
  display: inline-flex !important;
  justify-content: space-evenly;
  align-items: flex-end;
}

// Inline Evenly - Verticle Baseline
@mixin flex-evenly-baseline {
  display: flex !important;
  justify-content: space-evenly;
  align-items: baseline;
}
@mixin inline-flex-evenly-baseline {
  display: inline-flex !important;
  justify-content: space-evenly;
  align-items: baseline;
}

// Inline Evenly - Verticle Stretch
@mixin flex-evenly-stretch {
  display: flex !important;
  justify-content: space-evenly;
  align-items: stretch;
}
@mixin inline-flex-evenly-stretch {
  display: inline-flex !important;
  justify-content: space-evenly;
  align-items: stretch;
}






// Flex Box Classes Generator
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Flex box - Single Class Multi Property ~~~
    .flex#{$infix}-start  { @include flex-start; }
    .flex#{$infix}-center { @include flex-center; }
    .flex#{$infix}-end    { @include flex-end; }

    .flex#{$infix}-around   { @include flex-around; }
    .flex#{$infix}-between  { @include flex-between; }
    .flex#{$infix}-evenly   { @include flex-evenly; }

    .flex#{$infix}-top      { @include flex-top; }
    .flex#{$infix}-middle   { @include flex-middle; }
    .flex#{$infix}-bottom   { @include flex-middle; }
    .flex#{$infix}-baseline { @include flex-middle; }
    .flex#{$infix}-stretch  { @include flex-stretch; }

    .flex#{$infix}-start-top      { @include flex-start-top; }
    .flex#{$infix}-start-middle   { @include flex-start-middle; }
    .flex#{$infix}-start-bottom   { @include flex-start-bottom; }
    .flex#{$infix}-start-baseline { @include flex-start-baseline; }
    .flex#{$infix}-start-stretch  { @include flex-start-stretch; }

    .flex#{$infix}-center-top       { @include flex-center-top; }
    .flex#{$infix}-center-middle    { @include flex-center-middle; }
    .flex#{$infix}-center-bottom    { @include flex-center-bottom; }
    .flex#{$infix}-center-baseline  { @include flex-center-baseline; }
    .flex#{$infix}-center-stretch   { @include flex-center-stretch; }

    .flex#{$infix}-end-top      { @include flex-end-top; }
    .flex#{$infix}-end-middle   { @include flex-end-middle; }
    .flex#{$infix}-end-bottom   { @include flex-end-bottom; }
    .flex#{$infix}-end-baseline { @include flex-end-baseline; }
    .flex#{$infix}-end-stretch  { @include flex-end-stretch; }

    .flex#{$infix}-around-top       { @include flex-around-top; }
    .flex#{$infix}-around-middle    { @include flex-around-middle; }
    .flex#{$infix}-around-bottom    { @include flex-around-bottom; }
    .flex#{$infix}-around-baseline  { @include flex-around-baseline; }
    .flex#{$infix}-around-stretch   { @include flex-around-stretch; }

    .flex#{$infix}-between-top      { @include flex-between-top; }
    .flex#{$infix}-between-middle   { @include flex-between-middle; }
    .flex#{$infix}-between-bottom   { @include flex-between-bottom; }
    .flex#{$infix}-between-baseline { @include flex-between-baseline; }
    .flex#{$infix}-between-stretch  { @include flex-between-stretch; }

    .flex#{$infix}-evenly-top       { @include flex-evenly-top; }
    .flex#{$infix}-evenly-middle    { @include flex-evenly-middle; }
    .flex#{$infix}-evenly-bottom    { @include flex-evenly-bottom; }
    .flex#{$infix}-evenly-baseline  { @include flex-evenly-baseline; }
    .flex#{$infix}-evenly-stretch   { @include flex-evenly-stretch; }

    // Inline-Flex box - Single Class Multi Property ~~~
    .inline-flex#{$infix}-start   { @include inline-flex-start; }
    .inline-flex#{$infix}-center  { @include inline-flex-center; }
    .inline-flex#{$infix}-end     { @include inline-flex-end; }

    .inline-flex#{$infix}-around  { @include inline-flex-around; }
    .inline-flex#{$infix}-between { @include inline-flex-between; }
    .inline-flex#{$infix}-evenly  { @include inline-flex-evenly; }

    .inline-flex#{$infix}-top       { @include inline-flex-top; }
    .inline-flex#{$infix}-middle    { @include inline-flex-middle; }
    .inline-flex#{$infix}-bottom    { @include inline-flex-bottom; }
    .inline-flex#{$infix}-baseline  { @include inline-flex-baseline; }
    .inline-flex#{$infix}-stretch   { @include inline-flex-stretch; }

    .inline-flex#{$infix}-start-top       { @include inline-flex-start-top; }
    .inline-flex#{$infix}-start-middle    { @include inline-flex-start-middle; }
    .inline-flex#{$infix}-start-bottom    { @include inline-flex-start-bottom; }
    .inline-flex#{$infix}-start-baseline  { @include inline-flex-start-baseline; }
    .inline-flex#{$infix}-start-stretch   { @include inline-flex-start-stretch; }

    .inline-flex#{$infix}-center-top      { @include inline-flex-center-top; }
    .inline-flex#{$infix}-center-middle   { @include inline-flex-center-middle; }
    .inline-flex#{$infix}-center-bottom   { @include inline-flex-center-bottom; }
    .inline-flex#{$infix}-center-baseline { @include inline-flex-center-baseline; }
    .inline-flex#{$infix}-center-stretch  { @include inline-flex-center-stretch; }

    .inline-flex#{$infix}-end-top       { @include inline-flex-end-top; }
    .inline-flex#{$infix}-end-middle    { @include inline-flex-end-middle; }
    .inline-flex#{$infix}-end-bottom    { @include inline-flex-end-bottom; }
    .inline-flex#{$infix}-end-baseline  { @include inline-flex-end-baseline; }
    .inline-flex#{$infix}-end-stretch   { @include inline-flex-end-stretch; }

    .inline-flex#{$infix}-around-top      { @include inline-flex-around-top; }
    .inline-flex#{$infix}-around-middle   { @include inline-flex-around-middle; }
    .inline-flex#{$infix}-around-bottom   { @include inline-flex-around-bottom; }
    .inline-flex#{$infix}-around-baseline { @include inline-flex-around-baseline; }
    .inline-flex#{$infix}-around-stretch  { @include inline-flex-around-stretch; }

    .inline-flex#{$infix}-between-top       { @include inline-flex-between-top; }
    .inline-flex#{$infix}-between-middle    { @include inline-flex-between-middle; }
    .inline-flex#{$infix}-between-bottom    { @include inline-flex-between-bottom; }
    .inline-flex#{$infix}-between-baseline  { @include inline-flex-between-baseline; }
    .inline-flex#{$infix}-between-stretch   { @include inline-flex-between-stretch; }

    .inline-flex#{$infix}-evenly-top      { @include inline-flex-evenly-top; }
    .inline-flex#{$infix}-evenly-middle   { @include inline-flex-evenly-middle; }
    .inline-flex#{$infix}-evenly-bottom   { @include inline-flex-evenly-bottom; }
    .inline-flex#{$infix}-evenly-baseline { @include inline-flex-evenly-baseline; }
    .inline-flex#{$infix}-evenly-stretch  { @include inline-flex-evenly-stretch; }
  }
}
