
// :root {

//   //////////////////
//   // for Light Theme

//   /////////////////
//   // for Dark Theme
//   &[data-theme="dark"] {}
// }

// Setting Pages

// START : Payment Gateway Page
// accordion with icon
.ctm-accordion {
  overflow: hidden;

  &.accordion-icon {
    .accordion-item {
      border-radius: size(18);

      // border-top-right-radius: size(18);
      .accordion-header {
        display: flex;
        justify-content: space-between;
        font-size: size(18);
        font-weight: $fw-regular;

        .accordion-title-wrapper {
          width: calc(100% - 10%);
          display: flex;
          justify-content: space-between;
          align-items: center;

          .accordion-title-text {
            display: flex;
            align-items: center;
            line-height: 22px;
          }

          .ctm-accordion-button {
            padding: 0;
          }
        }

        .ctm-accordion-button {
          &[aria-expanded=true] {
            background: transparent;

            .bx {
              transition: 0.5s;
              transform: rotate(180deg);
            }
          }

          &[aria-expanded=false] {
            .bx {
              transition: 0.5s;
              transform: rotate(0deg);
            }
          }
        }
      }

      .accordion-collapse {
        border-top: size(1) solid var(--global-border-color);

        .accordion-body {
          padding: 0;
          border-top: 0;
        }
      }
    }
  }
}

// Disabled redio or checkbox 
.disabled-group {

  // if Not Checked
  &:not(:checked) {
    & ~ label {
      & ~ input {
        pointer-events: none;
        background-color: var(--global-border-color);
        opacity: 1;
      }
    }
  }
}

// Email Settings
.noti-toggle-wrapper {
  @include list-unstyled;
  margin: 0;
  display: grid;
  grid-gap: size(15);
  @include media-breakpoint-up(xxl) {
    grid-template-columns: repeat(auto-fit, minmax(calc(20% - size(15)), 1fr));
  }
  @include media-breakpoint-down(xxl) {
    grid-template-columns: repeat(auto-fit, minmax(calc(25% - size(15)), 1fr));
  }
  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(auto-fit, minmax(calc(33% - size(15)), 1fr));
  }
  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - size(15)), 1fr));
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(auto-fit, minmax(calc(100% - size(15)), 1fr));
  }
}
.noti-toggle-block {
  display: flex;

  & > .noti-toggle-label {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: size(10);
    margin: 0;
    border-radius: size(6);
    background-color: rgba($primary, 0.1);
    cursor: pointer;
  }

  .notification-text {
    @include font(16, 22);
    font-weight: $fw-semibold;
  }

  .notification-inst {
    @include font(12, 20);
    font-weight: $fw-regular;
  }
}

.payment-block {
  overflow: hidden;
}


// END : Payment Gateway Page
