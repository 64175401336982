
// for CSS Variables of Light & Dark Themes ~~~
:root {

  //////////////////
  // for Light Theme
  --global-border-color: #E1E1E1;
	--input-border-color: #CECECE;
  --offcanvas-border: var(--input-border-color);
  --offcanvass-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
  --nav-pills-tabs-border-color: rgba(0,0,0,0.20);

  // BS Variables Override
  --card-bg:                        var(--white);
  --card-border-color:              var(--global-border-color);
  --box-shadow:                     0 8px 16px rgba(0, 0, 0, 0.15);
  --box-shadow-sm:                  0 2px 4px rgba(0, 0, 0, 0.075);
  --box-shadow-lg:                  0 16px 48px rgba(0, 0, 0, 0.175);
  --box-shadow-inset:               inset 0 1px 2px rgba(0, 0, 0, 0.075);

  /////////////////
  // for Dark Theme
  &[data-theme="dark"] {

    --global-border-color: rgba(255, 255, 255, 0.2);
    --input-border-color: #505050;
    --offcanvas-border: var(--input-border-color);
    --offcanvass-shadow: 0 0.5rem 1rem rgba(255,255,255,0.15);
    --nav-pills-tabs-border-color: rgba(255,255,255,0.20);

    // BS Variables Override
    --card-bg:                      #252525;
    --card-border-color:            #1E1E1E;
    --box-shadow:                   0 8px 16px rgba(255, 255, 255, 0.15);
    --box-shadow-sm:                0 2px 4px rgba(255, 255, 255, 0.075);
    --box-shadow-lg:                0 16px 48px rgba(255, 255, 255, 0.175);
    --box-shadow-inset:             inset 0 1px 2px rgba(255, 255, 255, 0.075);
  }
}


// Theme Colors
$white        : #FFFFFF;
$black        : #000000;
$dark         : #111111;
$gray         : #A5A6B6;

$primary      : #00B2B9;
$secondary    : #FE8B00;
$tertiary     : #F45B69;
$success      : #47B872;
$info         : #1C3AA7;
$warning      : #F3C32F;
$danger       : #E02929;
$light        : #AEDAEA;


$theme-colors: (
  "primary"   : $primary,
  "secondary" : $secondary,
  "tertiary"  : $tertiary,
  "success"   : $success,
  "info"      : $info,
  "warning"   : $warning,
  "danger"    : $danger,
  "light"     : $light,

  // Colors that Change for Dark Mode
  "white"     : $white,
  "black"     : $black,
  "dark"      : $dark,
  "gray"      : $gray,
);
$theme-colors-dark: (
  "white"   : rgba(42, 42, 42, 1),
  "black"   : mix($black, white, 50%),
  // "dark"    : rgba(90, 104, 119, 1),
  "dark"    : mix($dark, white, 35%),
  "gray"    : rgba(155, 157, 164, 1),
);


// Font Family
$ff-roboto: 'Roboto', sans-serif;
$font-family-base: $ff-roboto;
$ff-box-icons: 'boxicons' !important;
$ff-icomoon: "icomoon" !important;

// Font-Weight
$fw-thin: 100;
$fw-extralight: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-extrabold: 800;
$fw-black: 900;
$fontWeights: (
  lighter: $fw-extralight,
  light: $fw-light,
  normal: $fw-regular,
  regular: $fw-regular,
  medium: $fw-medium,
  bold: $fw-bold,
  semibold: $fw-semibold,
  extrabold: $fw-extrabold,
  100: $fw-thin,
  200: $fw-extralight,
  300: $fw-light,
  400: $fw-regular,
  500: $fw-medium,
  600: $fw-semibold,
  700: $fw-bold,
  800: $fw-extrabold,
  900: $fw-black,
);

// Font Sizes
$font-size-base: 1rem;
$font-custom: 16; // for px to rem conversion

// Heading Font Size
$h1-font-size:                $font-size-base * 1.875;    // 30px
$h2-font-size:                $font-size-base * 1.5;      // 24px
$h3-font-size:                $font-size-base * 1.25;     // 20px
$h4-font-size:                $font-size-base * 1.125;    // 18px
$h5-font-size:                $font-size-base;            // 16px
$h6-font-size:                $font-size-base * 0.875;    // 14px

$headings-font-weight:        $fw-semibold;

$font-sizes: (
  12: $font-size-base * 0.75,
  14: $font-size-base * 0.875,
  16: $font-size-base,
  18: $font-size-base * 1.125,
  20: $font-size-base * 1.25,
  22: $font-size-base * 1.375,
  24: $font-size-base * 1.5,
);

// Line Heights
$lineHeights: (
  0: 0,
  1: 1,
  "1-25": 1.25,
  "1-5": 1.5,
  "1-75": 1.75,
  2: 2,
  "2-25": 2.25,
  "2-5": 2.5,
  12: $font-size-base * 0.75,
  14: $font-size-base * 0.875,
  16: $font-size-base,
  18: $font-size-base * 1.125,
  20: $font-size-base * 1.25,
  22: $font-size-base * 1.375,
  24: $font-size-base * 1.5,
);

// for Display Typography
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem
);

// Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1240px,
  xxl: 1440px,
  xxxl: 1560px
) !default;

// Media BreakPoint
$breakpoint-xxxl: 1560px;
$breakpoint-xxl: 1440px;


// Padding, Margin
$spacer: $font-size-base;
// Spacer
$spacers: (
  0: 0,
  1: $spacer * 0.0625,
  2: $spacer * 0.125,
  4: $spacer * 0.25,
  6: $spacer * 0.375,
  8: $spacer * 0.5,
  10: $spacer * 0.625,
  12: $spacer * 0.75,
  14: $spacer * 0.875,
  16: $spacer,
  18: $spacer * 1.125,
  20: $spacer * 1.25,
  24: $spacer * 1.5,
  28: $spacer * 1.75,
  30: $spacer * 1.875,
  32: $spacer * 2,
  36: $spacer * 2.25,
  44: $spacer * 2.75,
  50: $spacer * 3.125,
);

// Border
$border-size-base: $font-size-base;
$borders: (
  0: 0,
  1: $border-size-base * 0.0625,
  2: $border-size-base * 0.125,
  3: $border-size-base * 0.1875,
  4: $border-size-base * 0.25,
  5: $border-size-base * 0.3125,
);

// Border Radius
$border-radius-base: 1px;
$my-border-radius: (
  0: 0,
  2: $border-radius-base * 2,
  4: $border-radius-base * 4,
  5: $border-radius-base * 5,
  6: $border-radius-base * 6,
  8: $border-radius-base * 8,
  10: $border-radius-base * 10,
  12: $border-radius-base * 12,
  16: $border-radius-base * 16,
  20: $border-radius-base * 20,
  circle: $border-radius-base * 10000
);

// Line Clamp
$lines-clamp: (
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
);

// Avatar Sizes
$avatar-size-base: $font-size-base;
$avatar-sizes: (
  16: $avatar-size-base,
  24: $avatar-size-base * 1.5,
  32: $avatar-size-base * 2,
  40: $avatar-size-base * 2.5,
  52: $avatar-size-base * 3.25,
  120: $avatar-size-base * 7.5,
  176: $avatar-size-base * 11,
);


// BS Variables Override ~~~
// Shadows
$box-shadow: var(--box-shadow);
$box-shadow-sm: var(--box-shadow-sm);
$box-shadow-lg: var(--box-shadow-lg);
$box-shadow-inset: var(--box-shadow-inset);
// Accordion
$accordion-color: var(--black);
$accordion-bg: transparent;
// $accordion-button-active-bg: var(--white);
$accordion-button-active-bg: transparent;
// $accordion-button-bg: var(--white);
$accordion-button-bg: transparent;
$accordion-button-color: var(--dark);
$accordion-border-color: var(--global-border-color);
$accordion-transition: none;
// Card
$card-bg: var(--card-bg);
$card-cap-bg: var(--card-bg);
$card-border-color: var(--card-border-color);
// Drop Menus
$dropdown-bg: var(--white);
$dropdown-color: var(--black);
$dropdown-link-color: var(--black);
$dropdown-link-hover-color: shade-color($dropdown-link-color, 10%);
$dropdown-padding-y: 0;
$dropdown-border-width: 0;
$dropdown-link-hover-bg: transparent;
// Input Group
$input-group-addon-color: var(--black);
// List Group
$list-group-color: var(--black);
$list-group-bg: var(--white);
$list-group-border-color: var(--global-border-color);
// Table
$table-border-color: var(--global-border-color);
// Other
$link-decoration: none;
$badge-font-weight: normal;
$dropdown-min-width: 7.5rem;
// $container-padding-x: 1.875rem;
$input-font-size: $font-size-base;
$zindex-tooltip: 1030;

// Quickly modify global styling by enabling or disabling optional features.
$enable-negative-margins:                     true;
$enable-caret:                                true;
$enable-rounded:                              true;
$enable-shadows:                              false;
$enable-gradients:                            false;
$enable-transitions:                          true;
$enable-prefers-reduced-motion-media-query:   true;
$enable-hover-media-query:                    false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true;
$enable-pointer-cursor-for-buttons:           true;
$enable-print-styles:                         true;
$enable-responsive-font-sizes:                true;
$enable-validation-icons:                     true;
$enable-deprecation-messages:                 true;
