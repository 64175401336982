
// CALC FUNCTION RULE
// @mixin calc($property, $expression) {
//   #{$property}: calc(#{$expression});
// }
//  @include calc( width, '100% - 2px');

// PREFIX RULE 
@mixin css3-prefix($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}
//  @include css3-prefix(transform, scale3d(2.5, 2, 1.5));

// POSITION 
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// TEXT ELLIPSIS
@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Placeholder RULE
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}
//  @include placeholder {color:$gray;}

// Gradients
@mixin x-linear-gradient($direction, $fallback, $from, $to) {
  $directions: helper-gradient-angle($direction);
  // Provide a fallback-color
  background-color: $fallback;
  // Cross-browser linear-gradients
  background-image: -webkit-gradient(linear, $directions, from($from), to($to)); // Android 2.1-3.0
  background-image: -webkit-linear-gradient($directions, $from, $to);
  background-image: linear-gradient($direction, $from, $to);
}

//Theme Option

/*********************************************
================ FONT SIZE  ================== 
**********************************************/
@mixin font($font-size, $line-height: normal, $letter-spacing: normal) {
  // font-size: $font-size + px;
  // font-size: calculateEm($font-size);
  font-size: size($font-size);
  // example using rem values and 62.5% font-size so 1rem = 10px

  @if $line-height==normal {
    line-height: normal;
  }

  @else {

    // line-height: $line-height + px; //fallback for old browsers
    // line-height: ($line-height / $font-size) ;
    line-height: size($line-height);
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  }

  @else {
    // letter-spacing: #{$letter-spacing / $font-size}em;
    letter-spacing: size($letter-spacing);
  }
}

