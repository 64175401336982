

:root {
	//////////////////
	// for Light Theme



	/////////////////
	// for Dark Theme
	&[data-theme=dark] {
		.switch-theme {
			& ~ .switch-theme-label {
				background-color: rgba($white, 0.1);
			}
		}
	}
}


// Global for Vanila and React JS UI
.form-check-input,
.my-checkbox,
.my-radio {
	display: inline-block;
	flex-shrink: 0;
	width: size(16);
	height: size(16);
	background: transparent;
	background-image: none !important;
	border-style: solid;
	border-color: currentColor;
	color: $primary;
	position: relative;
	cursor: pointer;
	@include css3-prefix(appearance, none);

	&::before {
		color: inherit;
		z-index: 1;
		pointer-events: none;
	}

	// if Checked
	&:checked {
		&::before {
			opacity: 1;
		}

		&:disabled {
			filter: opacity(0.7) grayscale(1);
			cursor: not-allowed;

			& ~ label {
				filter: opacity(0.7) grayscale(1);
				cursor: not-allowed;
			}
		}
	}

	// if Not Checked
	&:not(:checked) {
		&:disabled {
			filter: opacity(0.7) grayscale(1);
			cursor: not-allowed;

			& ~ label {
				filter: opacity(0.7) grayscale(1);
				cursor: not-allowed;
			}
		}
	}

	// for Remove Focus
	&:focus,
	&:focus-visible {
		// border-color: inherit;
		outline: 0;
		outline-offset: 0;
		box-shadow: none;
	}

	// for Remove style of React BS
	&:active {
		filter: none;
	}

	& ~ label {
		&:not(.visually-hidden) {
			padding-inline-start: size(10);
			margin-bottom: 0;
			line-height: 1;
			color: var(--dark);
			@include css3-prefix (transition, all 0.2s ease);
			white-space: normal;
			cursor: pointer;
		}
	}

	// for Checkbox
	&[type="checkbox"] {
		border-width: size(2);
		border-radius: size(4);
		@include css3-prefix (transition, all 0.15s ease);

		&::before {
			content: "\e993";
			font-family: $ff-icomoon;
			font-size: size(12);
			line-height: 0;
			font-weight: $fw-semibold;
			color: $white;
			display: inline-block;
			// width: size(9);
			// height: size(5);
			// border-width: 0 0 size(2) size(2);
			// border-style: solid;
			// border-color: currentColor;
			position: absolute;
			// top: size(3);
			// left: size(3);
			top: 50%;
			left: 50%;
			// transform: rotateZ(-45deg) scale(0);
			transform: translate(-50%, -50%) scale(0);
			@include css3-prefix (transition, all 0.15s ease);
			opacity: 0;
		}

		&:checked {
			// background-color: transparent;
			background-color: currentColor;

			&::before {
				// transform: rotateZ(-45deg) scale(1);
				transform: translate(-50%, -50%) scale(1);
				opacity: 1;
			}
		}
	}

	// for Radio
	&[type="radio"] {
		border-width: size(2);
		border-radius: 100%;
		@include css3-prefix (transition, all 0.15s ease);

		&::before {
			content: "";
			position: absolute;
			top: 2px;
			right: 2px;
			bottom: 2px;
			left: 2px;
			background-color: currentColor;
			// background-color: transparent;
			// border: size(2) solid $white;
			border-radius: inherit;
			transform: scale(0);
			@include css3-prefix (transition, all 0.15s ease);
			// transition-delay: 0.15s;
		}

		// if Checked
		&:checked {
			// background-color: currentColor;

			&::before {
				transform: scale(1);
			}
		}
	}
}

// for Form-Check of React Bootstrap
.form-check {
	display: inline-flex;
	align-items: flex-start;
	padding: 0;
	margin: 0;

	.form-check-input {
		margin-top: 0;
		margin-left: 0;
		vertical-align: unset;
		float: unset;

		& ~ label {
			line-height: 1.2;
		}
	}

	.form-check-label,
	label {
		align-self: unset;
	}
}

// My Switch
.my-switch {
	--switch-width: 32px;
	// change the value of above variable to change the size of the switch
	--switch-height: calc(var(--switch-width) / 1.6);

	display: inline-block;
	flex-shrink: 0;
	width: var(--switch-width);
	height: var(--switch-height);
	background-color: rgba(120, 120, 128, 0.16);
	color: $success;
	border: 0;
	border-radius: size(1000);
	overflow: hidden;
	position: relative;
	cursor: pointer;
	@include css3-prefix(transition, background-color 0.25s ease);
	@include css3-prefix(appearance, none);

	&::before {
		content: none;
	}

	// for Focuse
	&:focus-visible {
		outline: 0;
		outline-offset: 0;
	}

	&::after {
		content: "";
		position: absolute;
		border-radius: inherit;
		@include css3-prefix(transition, all 0.25s ease);
	}

	&::after {
		top: 50%;
		left: size(2);
		transform: translateY(-50%);
		width: calc(var(--switch-height) - 4px);
		height: calc(var(--switch-height) - 4px);
		background-color: $white;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15), 0px 2px 0.5px rgba(0, 0, 0, 0.06);
		z-index: 1;
	}

	// for Checked
	&:checked {
		background-color: currentColor;

		&::after {
			left: calc(calc(var(--switch-height) / 1) + -6px);
		}
	}

	// for Disabled
	&:disabled {
		filter: opacity(0.7) grayscale(1);
		cursor: not-allowed;

		& ~ label {
			filter: opacity(0.7) grayscale(1);
			cursor: not-allowed;
		}
	}

	// if Not Checked
	&:not(:checked) {
		&:disabled {
			filter: opacity(0.7) grayscale(1);
			cursor: not-allowed;

			& ~ label {
				filter: opacity(0.7) grayscale(1);
				cursor: not-allowed;
			}
		}
	}

	& ~ label {
		&:not(.visually-hidden) {
			padding-inline-start: size(4);
			margin-bottom: 0;
			line-height: size(22);
			@include css3-prefix (transition, all 0.2s ease);
			cursor: pointer;
		}
	}
}

// for Form Switch of React Bootstrap
.form-switch {
	.form-check-input {
		@extend .my-switch;
	}
}

// Switch for Color Theme Switching
.switch-theme {
	display: none;

	// for remove Focus
	&:focus-visible {
		outline: 0;
		outline-offset: 0;
	}
	// if Checked
	&:checked {
		& ~ .switch-theme-label {
			&::before {
				left: 50%;
			}
		}
	}
	// if Disabled
	&:disabled {
		& ~ .switch-theme-label {
			filter: opacity(0.7) grayscale(1);
			cursor: not-allowed;
		}
	}
	// if Not Checked
	&:not(:checked) {
		&:disabled {
			& ~ .switch-theme-label {
				filter: opacity(0.7) grayscale(1);
				cursor: not-allowed;
			}
		}
	}
	// for Label
	& ~ .switch-theme-label {
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;
		padding: size(2);
		font-size: size(14);
		color: var(--black);
		background-color: rgba($black, 0.1);
		border: 0;
		border-radius: size(1000);
		overflow: hidden;
		position: relative;
		cursor: pointer;
		// for Switch Handle
		&::before {
			content: "";
			position: absolute;
			top: 2px;
			bottom: 2px;
			left: 2px;
			width: calc(50% - 2px);
			background-color: var(--white);
			border-radius: inherit;
			box-shadow: size(0) size(0) size(6) rgba(0, 0, 0, 0.35);
			z-index: 1;
			@include css3-prefix(transition, left 0.25s ease);
		}
		// for Box Icon
		.bx, .inic {
			display: flex;
			justify-content: center;
			padding: size(2) size(6);
			font-size: inherit;
			color: var(--black);
			z-index: 2;
		}
	}
}

// for Input Group and Inner Elements
.input-group {
	margin-bottom: size(16);

	// Global style
	&.input-text-icon,
	&.input-icon-text {
		.icon-wraper {
			position: absolute;
			top: 0;
			bottom: 0;
			display: grid;
			place-items: center;
			background: none;
			border: 0;
			padding: 0;
			margin: auto;
			width: size(40);
			color: var(--gray);
			z-index: 3;
		}
	}

	// for Textbox with icon on Inline-end
	&.input-text-icon {
		input {
			padding-inline-end: size(40);
		}

		.icon-wraper {
			right: 0;
		}
	}

	// // for Textbox with icon on Inline-start
	&.input-icon-text {
		input {
			padding-inline-start: size(40);
		}

		.icon-wraper {
			left: 0;
		}
	}

	// &:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
	// 	border-radius: size(6);
	// }
}
.input-group-text {
	background-color: var(--gray-100);
	border-color: var(--input-border-color);
}

// Textarea
textarea {
	min-height: size(80) !important;
}
.resize-none {
	resize: none;
}


//  toggle switch with img replace
.toggle-switch {
	display: inline-block;
	position: relative;

	[type="checkbox"] {
		width: 0;
		height: 0;
		visibility: hidden;
		position: absolute;
		z-index: 0;

		&:checked {
			& ~ label {
				.theme-light {display: none;}
				.theme-dark {display: inline-block;}
			}
		}
	}
	label {cursor: pointer;}
	.theme-light {display: inline-block;}
	.theme-dark {display: none;}
}


// for Mark User Favorite
.favorite-checkbox {
	display: none;

	& ~.favorite-checkbox-label {
		margin: 0;
		font-size: size(20);
		line-height: 1;
		color: $primary;
		cursor: pointer;

		.inic {
			color: inherit;
		}

		.favorite-checkbox-checked {
			display: none;
		}
	}

	&:checked {
		& ~.favorite-checkbox-label {
			.favorite-checkbox-checked {
				display: inline-block;
			}
			.favorite-checkbox-unchecked {
				display: none;
			}
		}
	}
}

.multiple-radio-check {

	.form-check  {
		.form-check-input {
			~ label {
				padding-left: 12px;
				font-size: size(16);
				line-height: 1.4;
			}
		}
	}
}

// Tiny Editer
.custom-editer {
	.tox-tinymce {
		border-width: 1px;
		background-color: var(--white);
		border-color: var(--input-border-color);
		&:not(.tox-tinymce-inline) .tox-editor-header {
			border-bottom: 1px solid var(--input-border-color);
		}
		.tox-toolbar-overlord,.tox-toolbar__primary,.tox-edit-area__iframe,
		.tox-statusbar,
		&:not(.tox-tinymce-inline) .tox-editor-header {
			background-color: var(--white);
			border-color: var(--input-border-color);
		}
		.tox-tbtn--bespoke {
			background-color: rgba($primary, 0.1);
			color: var(--dark);
		}
		.tox-tbtn {
			svg {
				fill: var(--dark);
			}
		}
		.tox-statusbar a, .tox-statusbar__path-item, .tox-statusbar__wordcount {
			color: var(--dark);
		}
		.tox-statusbar__branding {
			display: none;
		}
		.tox-statusbar__resize-handle {
			svg {
				fill: var(--dark);
			}
		}
	}
}
.tox {
	.tox-menu {
		background-color: var(--white) !important;
		border: 1px solid var(--input-border-color) !important;
	}
	.tox-collection__item {
		color: var(--dark) !important;
	}
	.tox-collection--list .tox-collection__item--active {
		background-color: rgba($primary, 0.1) !important;
	}
	.tox-collection--list .tox-collection__item--enabled {
		background-color: rgba($primary, 0.2) !important;
	}
}