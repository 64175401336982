
.rjs-datepicker {
  position: relative;

  &:after {
    content: "\e912";
    position: absolute;
    top: 50%;
    right: size(10);
    transform: translateY(-50%);
    font-family: $ff-icomoon;
    font-size: size(16);
    color: inherit;
    pointer-events: none;
  }

  input {
    @extend .form-control;
    padding-inline-end: size(30);
  }

  &.react-datepicker-wrapper {
    & ~ .react-datepicker {
      &__tab-loop {

        // for Current Day in Day Picker in Date Range Picker
        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range {
          background-color: var(--primary);
        }


        // for Time Picker in Date Range Picker
        .react-datepicker__time-container {
          width: 100px;
          .react-datepicker__time {
            .react-datepicker__time-box {
              width: 99px;
              ul {
                &.react-datepicker__time-list {
                  li {
                    &.react-datepicker__time-list-item--selected {
                      background-color: var(--primary);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.react-datepicker {
  background: var(--white);
  border: 1px solid rgba($gray, 0.2);
  border-radius: 10px;
  padding: 20px 20px;
  color: var(--dark);
  &__header {
    padding: 0;
    background: none;
    border: none;
  }
  &__current-month {
    font-size: size(18);
    color: var(--dark);
    @include media-breakpoint-down(xxxl) {
      font-size: size(16);
    }
  }
  &__day-names {
      display: flex;
      justify-content: space-between;
      margin: 0;
      border-top: 1px solid rgba($gray, 0.2);
      padding-top: 5px;
      margin-top: 10px;
  }
  &__day-name {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0;
    font-size: size(16);
    color: var(--dark);
    text-transform: uppercase;
    @include media-breakpoint-down(xxxl) {
      height: 34px;
      width: 34px;
      font-size: size(14);
    }
  }

  &__month {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 262px;
    height: 100%;
    margin: 0;
  }
  &__day--outside-month {
    color: var(--dark);
  }
  &__day {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 2px;
    line-height: 1.5;
    font-size: size(16);
    font-weight: $fw-regular;
    border-radius: 10px;
    color: var(--dark);
    @include media-breakpoint-down(xxxl) {
      height: 34px;
      width: 34px;
      font-size: size(14);
    }
    &:hover,
    &:focus {
      background-color: var(--primary);
      color: var(--white);
      border-radius: 10px;
    }
  }
  &__day--in-range,
  &__day--selected,
  &__day--keyboard-selected {
    background-color: var(--primary);
    color: var(--white);
  }
  &__week {
    display: flex;
    justify-content: space-between;
  }
  &__navigation-icon {
    top: 6px;
  }
  &__navigation--previous {
    left: 6px;
    top: 16px;
  }
  &__navigation--next {
    right: 6px;
    top: 16px;
  }
  &__navigation--next--with-time {
    &:not(.react-datepicker__navigation--next--with-today-button) {
      right: 122px;
    }
  }
  &__day--disabled{
    color: $gray;
  }

  // Time 
  &__header--time {
    &:not(.react-datepicker__header--time--only) {
      border-bottom: 1px solid $border-color;
      padding-bottom: 14px;
    }
  }
  &__time-container {
    margin-inline-start: 10px;
    border-color: $border-color;
  }
}